import { RouteRecordRaw } from "vue-router";

import { ATH_ROUTE_NAMES } from "./enums/ath.enums";
import AthDisciplineRootPage from "./views/AthDisciplineRootPage.vue";
import AthLivePage from "./views/AthLivePage.vue";
import AthResultRootPage from "./views/AthResultRootPage.vue";
import AthSchedulePage from "./views/AthSchedulePage.vue";
import AthFlashQuotesPage from "./views/misc/AthFlashQuotesPage.vue";
import AthRecordsPage from "./views/misc/AthRecordsPage.vue";
import AthReportsPage from "./views/misc/AthReportsPage.vue";
import AthEntryList from "./views/results/AthEntryListPage.vue";
import AthResultPage from "./views/results/AthResultPage.vue";
import AthSummaryPage from "./views/results/AthSummaryPage.vue";
import AthCombinedTotalsPage from "./views/standings/AthCombinedTotalsPage.vue";
import AthCupStandingsPage from "./views/standings/AthCupStandingsPage.vue";
import AthDiamondStandingPage from "./views/standings/AthDiamondStandingPage.vue";

export const routes: RouteRecordRaw[] = [
    {
        name: ATH_ROUTE_NAMES.SCHEDULE,
        path: "/schedule",
        component: AthSchedulePage
    },
    {
        name: ATH_ROUTE_NAMES.LIVE,
        path: "/live",
        component: AthLivePage
    },
    {
        name: ATH_ROUTE_NAMES.FLASH_QUOTES,
        path: "/flashquotes",
        component: AthFlashQuotesPage
    },
    {
        name: ATH_ROUTE_NAMES.DISCIPLINE,
        path: "/discipline/:eventRsc?/:phaseRsc?/:unitRsc?",
        component: AthDisciplineRootPage,
        props: true,
        children: [
            {
                path: "results",
                name: ATH_ROUTE_NAMES.RESULT,
                component: AthResultRootPage,
                props: true,
                // Careful with nested routes https://github.com/vuejs/router/issues/845, they may result in errors, when turning back to top level routes.
                children: [
                    {
                        name: ATH_ROUTE_NAMES.RESULT_DETAILS,
                        path: "result-details",
                        props: (route) => {
                            return { unitRsc: route.params.unitRsc };
                        },
                        component: AthResultPage
                    },
                    {
                        name: ATH_ROUTE_NAMES.SUMMARY,
                        path: "summary",
                        component: AthSummaryPage,
                        props: (route) => {
                            return { phaseRsc: route.params.phaseRsc };
                        }
                    }
                ]
            },
            {
                name: ATH_ROUTE_NAMES.COMBINED,
                path: "combined",
                component: AthCombinedTotalsPage,
                props: (route) => {
                    return { eventRsc: route.params.eventRsc };
                }
            },
            {
                name: ATH_ROUTE_NAMES.ENTRY_LIST,
                path: "entry-list",
                component: AthEntryList,
                props: (route) => {
                    return { eventRsc: route.params.eventRsc };
                }
            },
            {
                name: ATH_ROUTE_NAMES.RECORDS,
                path: "records/:recCode",
                component: AthRecordsPage,
                props: true
            },
            {
                name: ATH_ROUTE_NAMES.DIAMOND_STANDING,
                path: "diamond/:diamondId",
                component: AthDiamondStandingPage,
                props: true
            }
            // Flash Quotes
        ]
    },
    {
        name: ATH_ROUTE_NAMES.CUP_STANDINGS,
        path: "/cupStandings",
        component: AthCupStandingsPage
    },
    {
        name: ATH_ROUTE_NAMES.REPORTS,
        path: "/pdf",
        component: AthReportsPage
    },
    { path: "/", redirect: ATH_ROUTE_NAMES.SCHEDULE },
    { path: "/:catchAll(.*)", redirect: ATH_ROUTE_NAMES.SCHEDULE }
];
