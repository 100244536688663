import { ScheduleStatus } from "../enums/channel.enums";
import { getStrByLng } from "../stores/translation.store";

export const mapScheduleStatusToText = (statusCode: ScheduleStatus) => {
    switch (statusCode) {
        case ScheduleStatus.Planned:
        case ScheduleStatus.Scheduled:
            return getStrByLng().STATUS_SCHEDULED;
        case ScheduleStatus.Rescheduled:
            return getStrByLng().STATUS_RESCHEDULED;
        case ScheduleStatus.Delayed:
            return getStrByLng().STATUS_DELAYED;
        case ScheduleStatus.Interrupted:
            return getStrByLng().STATUS_INTERRUPTED;
        case ScheduleStatus.FinalJudgeCheck:
        case ScheduleStatus.ComingUp:
        case ScheduleStatus.GettingReady:
            return getStrByLng().STATUS_COMING_UP;
        case ScheduleStatus.Cancelled:
            return getStrByLng().STATUS_CANCELLED;
        case ScheduleStatus.Running:
            return getStrByLng().STATUS_RUNNING;
        case ScheduleStatus.Protested:
            return getStrByLng().STATUS_PROTESTED;
        case ScheduleStatus.Postponed:
            return getStrByLng().STATUS_POSTPONED;
        case ScheduleStatus.Break:
            return getStrByLng().STATUS_BREAK;
        case ScheduleStatus.Unofficial:
            return getStrByLng().STATUS_UNOFFICIAL;
        case ScheduleStatus.Official:
            return getStrByLng().STATUS_OFFICIAL;
        case ScheduleStatus.Finished:
            return getStrByLng().STATUS_FINISHED;
    }
    return "";
};
