import { orderBy } from "lodash-es";

/**
 * Function to insert an entry into an array only, if the condition is true.
 * @param condition - The condition to be met to insert an element or not.
 * @param elements - The element to consider inserting.
 */
export const insertIf = <T>(condition: boolean | (() => boolean), ...elements: T[]): T[] =>
    (typeof condition === "function" ? condition() : condition) ? elements : [];

/**
 * Function to turn an object into a sorted array by using a ListIndex property.
 * @param sourceObj - The object to turn into an array.
 * @param sortProperty - The property to order by.
 * @param sortDir - Decide if it should be sorted ascending or descending.
 */
export const objectToSortedArray = <T extends { [x: string]: any; ListIndex?: number }>(
    sourceObj: { [x: string]: T },
    sortProperty: keyof T = "ListIndex", // Default sort property is 'ListIndex'
    sortDir: "asc" | "desc" = "asc"
): T[] => orderBy(Object.values(sourceObj ?? {}), [sortProperty], sortDir);

/**
 * Function to map to data given in a object, which contains references by a key, which is used as a property name.
 * @param sourceArray - The array to take the keys/codes from.
 * @param referenceObj - The object, which contains the seeked data.
 * @param keyProperty - The property from the souceArray, which maps to the referenceObj.
 */
export const resolveArrayToObjectReference = <T extends { [p: string]: any }, K>(
    sourceArray: T[],
    referenceObj: K,
    keyProperty: keyof T
) =>
    sourceArray.map((entry) => {
        return {
            ...entry,
            ...referenceObj[entry[keyProperty]]
        };
    });
