<template>
    <div class="ath__records-page">
        <WtcChannel :channel-name="recordsChannelName" use-transition>
            <template #slot__result>
                <div>
                    <AthRecordsTable
                        v-for="category in recordCategories"
                        :key="category.Code"
                        :caption="category.Name"
                        :records="category.Records"
                    >
                    </AthRecordsTable>
                    <AthRecordsTable
                        :caption="$getStrByLng().LABEL_RECORD_NATIONAL"
                        :display-record-name="false"
                        :records="nationalRecords"
                    ></AthRecordsTable>
                </div>
            </template>
        </WtcChannel>
    </div>
</template>
<script setup lang="ts">
import { useFalconStore } from "@swisstiming/falcon-core";
import {
    getCompRecordsChannel,
    getStrByLng,
    objectToSortedArray,
    resolveArrayToObjectReference,
    useChannel,
    WtcChannel
} from "@swisstiming/webtec-kit";
import { storeToRefs } from "pinia";
import { computed } from "vue";

import AthRecordsTable from "../../components/elements/records/AthRecordsTable.vue";
import { IAthRecordsChannel } from "../../interfaces/ath.interfaces";
const props = defineProps({
    recCode: String
});
const { config } = storeToRefs(useFalconStore());
const recordsChannelName = computed(() => getCompRecordsChannel(config.value.tournamentId, props.recCode));
const { content: recordsChan } = useChannel<IAthRecordsChannel>(recordsChannelName);

const sortedCategories = computed(() => objectToSortedArray(recordsChan.value.OtherRecords));

const recordCategories = computed(() =>
    sortedCategories.value.map((category) => {
        let name: string;
        switch (category.Code) {
            case "GLO":
                name = getStrByLng().LABEL_RECORD_GLOBAL;
                break;
            case "ARE":
                name = getStrByLng().LABEL_RECORD_AREA;
                break;
            case "BPF":
                name = getStrByLng().LABEL_RECORD_YEAR_BEST;
                break;
            case "NAT":
                name = getStrByLng().LABEL_RECORD_NATIONAL;
                break;
            case "LOC":
                name = getStrByLng().LABEL_RECORD_LOCAL;
                break;
        }
        return {
            ...category,
            Name: name,
            Records: resolveArrayToObjectReference(category.Records, recordsChan.value.RecordDetails, "Code")
        };
    })
);

const nationalRecords = computed(() =>
    resolveArrayToObjectReference(recordsChan.value.NationalRecords, recordsChan.value.RecordDetails, "Code")
);
</script>

<style scoped lang="scss"></style>
