import { handleWarn, IRsc } from "@swisstiming/webtec-kit";
import { useRouter } from "vue-router";

import { ATH_ROUTE_NAMES } from "../enums/ath.enums";

export const useAthRoutes = () => {
    const router = useRouter();

    const routeToResult = (rsc: IRsc) => {
        router
            .push({
                name: ATH_ROUTE_NAMES.RESULT_DETAILS,
                params: { unitRsc: rsc?.Value, phaseRsc: rsc?.ValuePhase, eventRsc: rsc?.ValueEvent }
            })
            .catch(handleWarn);
    };
    const hrefToResult = (rsc: IRsc) =>
        router.resolve({
            name: ATH_ROUTE_NAMES.RESULT_DETAILS,
            params: { unitRsc: rsc?.Value, phaseRsc: rsc?.ValuePhase, eventRsc: rsc?.ValueEvent }
        })?.href;

    return { routeToResult, hrefToResult };
};
