import { computed, Ref } from "vue";

import { IBinariesChannel } from "../interfaces/channel/binaries";
import { PSClientManager } from "../manager/pushserver-client.manager";
import { getBinariesChannel, getEventBinariesChannel } from "../utils/channel.name-utils";
import { useChannel } from "./channel.composables";

export const useBinariesChannel = (binariesChannelName: Ref<string>) => {
    const { status, content: rawData } = useChannel<IBinariesChannel>(binariesChannelName);

    return { status, rawData };
};

export const usePSBinaryEntry = (binaryKey: Ref<string>, nameSpace: Ref<string>, tournamentId?: Ref<string>) => {
    const binariesChannelName = computed(() => {
        return tournamentId.value ? getEventBinariesChannel(tournamentId.value) : getBinariesChannel();
    });

    const { rawData: binariesChannel } = useBinariesChannel(binariesChannelName);

    const binaryEntry = computed(() => {
        return binariesChannel.value[binaryKey.value];
    });

    const binaryFileUrl = computed(() =>
        binaryEntry.value
            ? `https://${PSClientManager.getClientInstance().getDataHost()}/node/binaryData/${nameSpace.value}/${tournamentId.value ? tournamentId.value.toUpperCase() + "/" : ""}${binaryEntry.value.OriginalName}${binaryEntry.value.Hash ? "?h=" + binaryEntry.value.Hash : ""}`
            : undefined
    );
    return { binaryEntry, binaryFileUrl, binariesChannel };
};
