<template>
    <!-- TODO: SCHAND > ich habe die tendenzen als before element gleich mit einem uni code zeichen "dargestellt". Falls du
         das anders machen willst, tell me. Other than that, das soll sicherlich noch ein paar andere styles bekommen. -->

    <FalconRankIrm :class="tendencyClass" :competitor></FalconRankIrm>
</template>
<script setup lang="ts">
import { FalconRankIrm } from "@swisstiming/falcon-core";
import { computed } from "vue";

import { IAthCompetitorDetail } from "../../../interfaces/ath.interfaces";

const props = defineProps<{
    competitor?: IAthCompetitorDetail;
}>();

const getTendencyIndication = (tendencyType: string, tendencyValue: string) => {
    switch (tendencyType) {
        case "+":
            return `↑${tendencyValue}`;
        case "-":
            return `↓${tendencyValue}`;
        case "=":
            return `=${tendencyValue}`;
    }
};

const tendencyClass = computed(() => {
    const tendency = props.competitor?.Stats?.Live_Tendency;
    const tendencyValue = props.competitor?.Stats?.Live_TendencyValue;
    return tendency && tendencyValue ? `before:content-['${getTendencyIndication(tendency, tendencyValue)}']` : "";
});
</script>
