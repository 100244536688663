export enum AthDiamondLeaguePhaseTypes {
    QUALIFICATION = "QUALIFICATION",
    FINAL = "FINAL"
}

export enum AthDisciplineTypes {
    RUN = "RUN",
    WIDTH = "WIDTH",
    HEIGHT = "HEIGHT",
    RELAY = "RELAY",
    ROAD = "ROAD",
    NON_EVENT = "NONEVENT",
    ROAD_TEAM = "ROAD_TEAM"
}

export enum ATH_ROUTE_NAMES {
    SCHEDULE = "schedule",
    SUMMARY = "summary",
    CUP_STANDINGS = "cupStandings",
    FLASH_QUOTES = "flashquotes",
    REPORTS = "reports",
    LIVE = "live",
    DISCIPLINE = "discipline",
    RESULT = "result",
    RESULT_DETAILS = "details",
    RUN_RESULTS = "run-results",
    WIDTH_JUMP_RESULTS = "width-results",
    HEIGHT_JUMP_RESULTS = "height-results",
    COMBINED = "combined",
    ENTRY_LIST = "entryList",
    RECORDS = "records",
    DIAMOND_STANDING = "diamondStanding"
}

export enum ATH_PROFILES {
    WDL = "wdl",
    DLV = "dlv",
    DLV_COMBINED = "dlv_combined",
    DLV_CHAMPIONSHIP = "dlv_championship",
    ISTAF = "istaf",
    GOETZIS = "goetzis",
    OMEGA = "omega",
    NEUTRAL = "neutral",
    ZUERICH = "zuerich",
    KARLSRUHE = "karlsruhe",
    EUROPEAN_CHAMPIONSHIP = "euch"
}

export enum ATH_PDF_KEYS {
    FLASH_INTERVIEW = "FLASH"
}
