import { padEnd } from "lodash-es";

export const RscCode = class Rsc {
    LongEvent: string;
    Event: string;
    Phase: string;
    Discipline: string;
    Gender: string;

    ValueEvent: string;
    ValuePhase: string;
    Value: string;

    IsDiscipline: boolean;
    IsEvent: boolean;
    IsGender: boolean;
    IsPhase: boolean;
    IsUnit: boolean;

    constructor(rscCode) {
        Object.defineProperty(this, "Value", {
            writable: false,
            enumerable: false,
            configurable: false,
            value: rscCode
        });
        if (this.Value !== undefined) {
            if (this.Value.length === 34) {
                Object.defineProperty(this, "Discipline", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(0, 3)
                });
                Object.defineProperty(this, "Gender", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(3, 1)
                });
                Object.defineProperty(this, "Event", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(4, 18)
                });
                Object.defineProperty(this, "Phase", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(22, 4)
                });
                Object.defineProperty(this, "Unit", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(26, 6)
                });
                Object.defineProperty(this, "SubUnit", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(32, 2)
                });
                Object.defineProperty(this, "LongEvent", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(0, 22)
                });
                Object.defineProperty(this, "ValuePhase", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: padEnd(this.LongEvent + this.Phase, 34, "-")
                });
                Object.defineProperty(this, "ValueEvent", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: padEnd(this.LongEvent, 34, "-")
                });

                Object.defineProperty(this, "IsDiscipline", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: padEnd(this.Discipline, 34, "-") === this.Value
                });
                Object.defineProperty(this, "IsGender", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: !this.IsDiscipline && padEnd(this.Discipline + this.Gender, 34, "-") === this.Value
                });
                Object.defineProperty(this, "IsEvent", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value:
                        !this.IsDiscipline &&
                        !this.IsGender &&
                        padEnd(this.Discipline + this.Gender + this.Event, 34, "-") === this.Value
                });
                Object.defineProperty(this, "IsPhase", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value:
                        !this.IsDiscipline &&
                        !this.IsGender &&
                        !this.IsEvent &&
                        padEnd(this.Discipline + this.Gender + this.Event + this.Phase, 34, "-") === this.Value
                });
                Object.defineProperty(this, "IsUnit", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: !this.IsDiscipline && !this.IsGender && !this.IsEvent && !this.IsPhase
                });
            } else if (this.Value.length === 9) {
                Object.defineProperty(this, "Discipline", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(0, 2)
                });
                Object.defineProperty(this, "Gender", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(2, 1)
                });
                Object.defineProperty(this, "Event", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(3, 3)
                });
                Object.defineProperty(this, "Phase", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(6, 1)
                });
                Object.defineProperty(this, "Unit", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(7, 2)
                });
                Object.defineProperty(this, "SubUnit", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: undefined
                });
                Object.defineProperty(this, "LongEvent", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(0, 6)
                });
                Object.defineProperty(this, "ValuePhase", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: padEnd(this.LongEvent + this.Phase, 9, "0")
                });
                Object.defineProperty(this, "ValueEvent", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: padEnd(this.LongEvent, 9, "0")
                });

                Object.defineProperty(this, "IsDiscipline", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: padEnd(this.Discipline, 9, "0") === this.Value
                });
                Object.defineProperty(this, "IsGender", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: !this.IsDiscipline && padEnd(this.Discipline + this.Gender, 9, "0") === this.Value
                });
                Object.defineProperty(this, "IsEvent", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value:
                        !this.IsDiscipline &&
                        !this.IsGender &&
                        padEnd(this.Discipline + this.Gender + this.Event, 9, "0") === this.Value
                });
                Object.defineProperty(this, "IsPhase", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value:
                        !this.IsDiscipline &&
                        !this.IsGender &&
                        !this.IsEvent &&
                        padEnd(this.Discipline + this.Gender + this.Event + this.Phase, 9, "0") === this.Value
                });
                Object.defineProperty(this, "IsUnit", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: !this.IsDiscipline && !this.IsGender && !this.IsEvent && !this.IsPhase
                });
            }
        }
    }
};

interface RscInfo {
    LongEvent?: string;
    Event?: string;
    Phase?: string;
    Discipline?: string;
    Gender?: string;
    ValueEvent?: string;
    ValuePhase?: string;
    Value?: string;
    Unit?: string;
    SubUnit?: string;
    IsDiscipline?: boolean;
    IsEvent?: boolean;
    IsGender?: boolean;
    IsPhase?: boolean;
    IsUnit?: boolean;
}

export const parseRsc = (rscCode: string): RscInfo => {
    if (!rscCode) return {};

    const parseRsc34 = (code: string): RscInfo => {
        const discipline = code.slice(0, 3);
        const gender = code.slice(3, 4);
        const event = code.slice(4, 22);
        const phase = code.slice(22, 26);
        const unit = code.slice(26, 32);
        const subUnit = code.slice(32, 34);
        const longEvent = code.slice(0, 22);

        const valuePhase = padEnd(longEvent + phase, 34, "-");
        const valueEvent = padEnd(longEvent, 34, "-");

        const isDiscipline = padEnd(discipline, 34, "-") === code;
        const isGender = !isDiscipline && padEnd(discipline + gender, 34, "-") === code;
        const isEvent = !isDiscipline && !isGender && padEnd(discipline + gender + event, 34, "-") === code;
        const isPhase =
            !isDiscipline && !isGender && !isEvent && padEnd(discipline + gender + event + phase, 34, "-") === code;
        const isUnit = !isDiscipline && !isGender && !isEvent && !isPhase;

        return {
            Value: code,
            Discipline: discipline,
            Gender: gender,
            Event: event,
            Phase: phase,
            Unit: unit,
            SubUnit: subUnit,
            LongEvent: longEvent,
            ValuePhase: valuePhase,
            ValueEvent: valueEvent,
            IsDiscipline: isDiscipline,
            IsGender: isGender,
            IsEvent: isEvent,
            IsPhase: isPhase,
            IsUnit: isUnit
        };
    };

    const parseRsc9 = (code: string): RscInfo => {
        const discipline = code.slice(0, 2);
        const gender = code.slice(2, 3);
        const event = code.slice(3, 6);
        const phase = code.slice(6, 7);
        const unit = code.slice(7, 9);
        const longEvent = code.slice(0, 6);

        const valuePhase = padEnd(longEvent + phase, 9, "0");
        const valueEvent = padEnd(longEvent, 9, "0");

        const isDiscipline = padEnd(discipline, 9, "0") === code;
        const isGender = !isDiscipline && padEnd(discipline + gender, 9, "0") === code;
        const isEvent = !isDiscipline && !isGender && padEnd(discipline + gender + event, 9, "0") === code;
        const isPhase =
            !isDiscipline && !isGender && !isEvent && padEnd(discipline + gender + event + phase, 9, "0") === code;
        const isUnit = !isDiscipline && !isGender && !isEvent && !isPhase;

        return {
            Value: code,
            Discipline: discipline,
            Gender: gender,
            Event: event,
            Phase: phase,
            Unit: unit,
            LongEvent: longEvent,
            ValuePhase: valuePhase,
            ValueEvent: valueEvent,
            IsDiscipline: isDiscipline,
            IsGender: isGender,
            IsEvent: isEvent,
            IsPhase: isPhase,
            IsUnit: isUnit
        };
    };

    return rscCode.length === 34 ? parseRsc34(rscCode) : rscCode.length === 9 ? parseRsc9(rscCode) : {};
};
