import { TransitionProps } from "vue";

export const passThroughTransitionFade: TransitionProps = {
    enterFromClass: "opacity-0",
    leaveToClass: "opacity-0",
    enterActiveClass: "transition duration-200",
    leaveActiveClass: "transition duration-200"
};

export const passThroughTransitionSlideDown: TransitionProps = {
    enterFromClass: "-translate-y-1 opacity-0",
    leaveToClass: "-translate-y-1 opacity-0",
    enterActiveClass: "transition duration-200",
    leaveActiveClass: "transition duration-200"
};

export const passThroughTransitionSlideUp: TransitionProps = {
    enterFromClass: "translate-y-1 opacity-0",
    leaveToClass: "translate-y-1 opacity-0",
    enterActiveClass: "transition duration-200",
    leaveActiveClass: "transition duration-200"
};
