<template>
    <div class="ath__result-page">
        <WtcChannel :channel-name="channelNames.compStructure">
            <template #slot__result>
                <WtcTransition appear :delay-ms="50" name="slide-left-only-in">
                    <header>
                        <div class="mb-2 flex items-center">
                            <WtcImage
                                v-if="unit?.Stats?.DiamondType"
                                class="mr-2 h-4 w-5"
                                :url="diamondImageUrl"
                            ></WtcImage>
                            <div class="font-normal_medium text-2xl">
                                {{ useTranslatedProperty(event, "Name") }}
                            </div>
                        </div>
                        <nav class="discipline-navigation">
                            <ul class="bg-surface-alternate max-mobile:gap-0 mb-4 flex flex-wrap justify-evenly p-1">
                                <AthRouterLink
                                    class="max-mobile:flex-auto max-mobile:basis-2/4 flex-1"
                                    :class="{
                                        'bg-brand text-white':
                                            activeRoute.matched.find((item) => item.name == ATH_ROUTE_NAMES.RESULT) !==
                                            undefined
                                    }"
                                    :params="{ unitRsc }"
                                    :pt="{
                                        content: 'flex items-center justify-center p-2 text-sm'
                                    }"
                                    :route-label="$getStrByLng().LABEL_RESULTS"
                                    :route-name="ATH_ROUTE_NAMES.RESULT_DETAILS"
                                ></AthRouterLink>

                                <AthRouterLink
                                    v-if="event.Stats?.Combined === 'y' && event?.Rsc"
                                    class="max-mobile:flex-auto max-mobile:basis-2/4 flex-1"
                                    :pt="{
                                        active: 'bg-brand text-white',
                                        content: 'flex items-center justify-center p-2 text-sm'
                                    }"
                                    :route-label="$getStrByLng().NAVIGATION_COMBINED_TOTAL_RES"
                                    :route-name="ATH_ROUTE_NAMES.COMBINED"
                                ></AthRouterLink>
                                <AthRouterLink
                                    v-if="athCisConfig.Config?.EntryListEnabled && event?.Rsc"
                                    class="max-mobile:flex-auto max-mobile:basis-2/4 flex-1"
                                    :pt="{
                                        active: 'bg-brand text-white',
                                        content: 'flex items-center justify-center p-2 text-sm'
                                    }"
                                    :route-label="$getStrByLng().LABEL_ENTRY_LIST"
                                    :route-name="ATH_ROUTE_NAMES.ENTRY_LIST"
                                ></AthRouterLink>

                                <AthRouterLink
                                    v-if="isDiamondQualiCompetition"
                                    class="max-mobile:flex-auto max-mobile:basis-2/4 flex-1"
                                    :params="{ diamondId }"
                                    :pt="{
                                        active: 'bg-brand text-white',
                                        content: 'flex items-center justify-center p-2 text-sm'
                                    }"
                                    :route-label="$getStrByLng().BTN_DIAMOND_QUALIFICATION"
                                    :route-name="ATH_ROUTE_NAMES.DIAMOND_STANDING"
                                ></AthRouterLink>

                                <AthRouterLink
                                    v-if="recordsCode"
                                    class="max-mobile:flex-auto max-mobile:basis-2/4 flex-1"
                                    :params="{ recCode: recordsCode }"
                                    :pt="{
                                        active: 'bg-brand text-white',
                                        content: 'flex items-center justify-center px-6 py-2 text-sm'
                                    }"
                                    :route-label="$getStrByLng().NAVIGATION_RECORDS"
                                    :route-name="ATH_ROUTE_NAMES.RECORDS"
                                ></AthRouterLink>
                            </ul>
                        </nav>
                    </header>
                </WtcTransition>

                <router-view></router-view>
            </template>
            <template #slot__not-initialized>
                <FalconPlaceHolder msg="no-data"></FalconPlaceHolder>
            </template>
        </WtcChannel>
    </div>
</template>

<script setup lang="ts">
import { FalconPlaceHolder, useFalconStore, useTranslatedProperty } from "@swisstiming/falcon-core";
import { RscCode, useCompStrucChannel, WtcChannel, WtcImage, WtcTransition } from "@swisstiming/webtec-kit";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRouter } from "vue-router";

import diamondImageUrl from "../assets/images/diamond.svg";
import AthRouterLink from "../components/elements/AthRouterLink.vue";
import { ATH_ROUTE_NAMES, AthDiamondLeaguePhaseTypes } from "../enums/ath.enums";
import { IAthCisConfigChannel } from "../interfaces/ath.interfaces";

const props = defineProps({
    unitRsc: {
        type: String,
        required: true
    }
});

const activeRoute = useRouter().currentRoute;
const { channelNames, data } = storeToRefs(useFalconStore());

const { event, unit } = useCompStrucChannel(computed(() => channelNames.value.compStructure)).getNodeEntries(
    computed(() => new RscCode(props.unitRsc))
);

const recordsCode = computed(() => event.value?.Stats?.RecCode);
const athCisConfig = computed(() => data.value.misConfigData as IAthCisConfigChannel);

const diamondId = computed(() => event.value?.Stats?.DiamondId);
const isDiamondQualiCompetition = computed(
    () => diamondId.value !== undefined && unit.value.Stats?.DiamondType === AthDiamondLeaguePhaseTypes.QUALIFICATION
);
</script>

<style></style>
