<template>
    <WtcChannel :channel-name="channelName" use-transition>
        <template #slot__result>
            <div>
                <AthHeaderRecordsList
                    :rec-code="timingData.Stats?.RecCode"
                    :records="timingData.Records"
                ></AthHeaderRecordsList>

                <table class="mt-4 w-full">
                    <thead>
                        <tr>
                            <!-- Nation -->
                            <th class="w-16 min-w-16">{{ $getStrByLng().HEADER_NAT }}</th>
                            <!-- Name -->
                            <th class="text-left">{{ $getStrByLng().HEADER_NAME }}</th>
                            <!-- Seasonal best -->
                            <th class="w-16 min-w-16">{{ $getStrByLng().HEADER_SEASONAL_BEST }}</th>
                            <!-- Personal best -->
                            <th class="w-16 min-w-16">{{ $getStrByLng().HEADER_PERSONAL_BEST }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="competitor in sortedStartList" :key="competitor.Id">
                            <!-- Nation -->
                            <td><FalconFlag :nat-code="competitor.Nationality"></FalconFlag></td>
                            <!-- Name -->
                            <AthNameColumn :competitor="competitor" use-team-member></AthNameColumn>
                            <!-- Seasonal best -->
                            <td>{{ competitor.Stats?.SB }}</td>
                            <!-- Personal best -->
                            <td>{{ competitor.Stats?.PB }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </WtcChannel>
</template>
<script setup lang="ts">
import { FalconFlag } from "@swisstiming/falcon-core";
import { WtcChannel } from "@swisstiming/webtec-kit";
import { toRef } from "vue";

import AthHeaderRecordsList from "../../components/elements/records/AthHeaderRecordsList.vue";
import AthNameColumn from "../../components/elements/tables/AthNameColumn.vue";
import { useAthTimingChannel } from "../../composables/ath-data.composables";

const props = defineProps({
    eventRsc: String
});
const { timingData, sortedStartList, channelName } = useAthTimingChannel(toRef(props, "eventRsc"));
</script>

<style scoped lang="scss"></style>
