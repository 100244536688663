<template>
    <WtcPdfHyperLink
        :binaryName
        class="hover:underline"
        :name-space="config.namespace"
        :title
        :tournament-id="config.tournamentId"
        @click.stop
    ></WtcPdfHyperLink>
</template>
<script setup lang="ts">
import { useFalconStore } from "@swisstiming/falcon-core";
import { WtcPdfHyperLink } from "@swisstiming/webtec-kit";
import { storeToRefs } from "pinia";

defineProps({
    binaryName: String,
    title: String
});

const { config } = storeToRefs(useFalconStore());
</script>

<style scoped lang="scss"></style>
