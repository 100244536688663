<template>
    <div>
        <component :is="resultComponent" :unitRsc="phaseRsc"></component>
    </div>
</template>
<script setup lang="ts">
import { computed, toRef } from "vue";

import { useAthTimingChannel } from "../../composables/ath-data.composables";
import { AthDisciplineTypes } from "../../enums/ath.enums";
import AthHighJumpPage from "./AthHighJumpPage.vue";
import AthLongJumpPage from "./AthLongJumpPage.vue";
import AthRunPage from "./AthRunPage.vue";

const props = defineProps<{ phaseRsc: string }>();

const { timingData } = useAthTimingChannel(toRef(props, "phaseRsc"));

const resultComponent = computed(() => {
    switch (timingData.value.Stats?.Type) {
        case AthDisciplineTypes.RUN:
        case AthDisciplineTypes.RELAY:
            return AthRunPage;
        case AthDisciplineTypes.WIDTH:
            return AthLongJumpPage;
        case AthDisciplineTypes.HEIGHT:
            return AthHighJumpPage;
    }
    return AthRunPage;
});
</script>

<style scoped lang="scss"></style>
