<template>
    <WtcChannel :channel-name="channelName" use-transition>
        <template #slot__result>
            <div :key="unitRsc" class="ath__run-page">
                <AthResultHeader :rsc="unitRsc"></AthResultHeader>
                <div v-if="photoEntry" class="max-mobile:justify-start flex justify-end">
                    <WtcButton
                        class="mb-1 text-sm"
                        :pt="{
                            label: 'text-brand-secondary hover:underline'
                        }"
                        :text="$getStrByLng().NAVIGATION_PHOTO_FINISH"
                        @click="photoFinishVisible = true"
                    />
                    <Dialog
                        v-model:visible="photoFinishVisible"
                        :closable="true"
                        dismissable-mask
                        modal
                        :pt="{
                            mask: 'bg-content/30 backdrop-blur-sm',
                            root: 'relative',
                            headerActions: 'absolute right-5 top-5'
                        }"
                    >
                        <WtcImage
                            :alt="'Photo finish is not available at this time.'"
                            class="bg-surface flex min-w-96 items-center justify-evenly self-center px-5 py-12"
                            :url="photoUrl"
                        ></WtcImage>
                    </Dialog>
                </div>
                <div class="flex flex-col gap-3">
                    <div class="max-tablet:overflow-x-auto">
                        <table class="w-full border border-black/10">
                            <thead>
                                <tr>
                                    <th class="sticky left-0 w-14 min-w-14">{{ $getStrByLng().HEADER_RANK }}</th>
                                    <th class="w-14 min-w-14">{{ laneLabel }}</th>
                                    <AthBibHeadColumn class="w-14 min-w-14"></AthBibHeadColumn>
                                    <th class="w-20 min-w-20 text-left">{{ $getStrByLng().HEADER_NAT }}</th>
                                    <th class="text-left">{{ $getStrByLng().HEADER_NAME }}</th>
                                    <th class="w-40 min-w-40 text-left">{{ $getStrByLng().HEADER_TIME }}</th>
                                    <AthDiamondRaceHeadColumns v-if="isDiamondQualiRace"></AthDiamondRaceHeadColumns>
                                    <AthCombinedPointsHeadColumns
                                        v-if="isCombinedDiscipline"
                                    ></AthCombinedPointsHeadColumns>
                                    <AthBestHeadColumns></AthBestHeadColumns>
                                </tr>
                            </thead>
                            <WtcTransitionGroup name="list-sort" tag="tbody">
                                <tr
                                    v-for="competitor in sortedResultList as IAthCompetitorDetail[]"
                                    :key="competitor.Id"
                                >
                                    <AthRankColumn class="sticky left-0" :competitor></AthRankColumn>
                                    <td class="lane">{{ competitor.StartPos }}</td>
                                    <AthBibColumn :competitor></AthBibColumn>
                                    <FalconFlag :nat-code="competitor.Nationality" tag="td"></FalconFlag>

                                    <AthNameColumn :competitor use-team-member></AthNameColumn>

                                    <!-- Time -->
                                    <AthComplexResultColumn
                                        :class="{
                                            '!bg-surface-highlightLight': competitor.Stats?.Live_LeaderLap !== undefined
                                        }"
                                        :competitor
                                    >
                                        <template #default>
                                            <!-- All the run specific additions being displayed along with the time  -->
                                            <WtcImage
                                                v-if="competitor.Stats?.Pace"
                                                class="mr-1 w-5"
                                                :url="paceImageUrl"
                                            ></WtcImage>
                                            <span v-if="displaySplitTimes" class="text-xs">{{
                                                competitor.Stats?.Live_Split
                                            }}</span>
                                            <span
                                                v-if="competitor.Stats?.FalseStart"
                                                class="text-content-negative text-nowrap px-1 text-xs"
                                                >{{ $getStrByLng().MESSAGE_FALSE_START }}</span
                                            >
                                        </template>
                                    </AthComplexResultColumn>

                                    <AthDiamondRaceColumns
                                        v-if="isDiamondQualiRace"
                                        :competitor
                                        :diamond-id="unitTimingData.Stats?.DiamondId"
                                    ></AthDiamondRaceColumns>

                                    <AthCombinedPointsColumns
                                        v-if="isCombinedDiscipline"
                                        :competitor="competitor as IAthCompetitorDetail"
                                    ></AthCombinedPointsColumns>

                                    <AthBestColumns :competitor="competitor as IAthCompetitorDetail"></AthBestColumns>
                                </tr>
                            </WtcTransitionGroup>
                        </table>
                    </div>

                    <div class="max-laptop:flex-col flex gap-2">
                        <div class="max-tablet:overflow-x-auto">
                            <table v-if="unitTimingData.OfficialSplits" class="ath__run-results-splits">
                                <thead>
                                    <tr>
                                        <th class="w-28 min-w-28">{{ $getStrByLng().HEADER_DISTANCE }}</th>
                                        <th class="w-20 min-w-20 text-left">{{ $getStrByLng().HEADER_NAT }}</th>
                                        <th>{{ $getStrByLng().HEADER_NAME }}</th>
                                        <th class="w-28 min-w-28 text-left">{{ $getStrByLng().HEADER_TIME }}</th>
                                    </tr>
                                </thead>
                                <WtcTransitionGroup name="slide" tag="tbody">
                                    <tr v-for="split in joinedOfficialSplits" :key="split.Name">
                                        <td>{{ split.Name }}</td>
                                        <FalconFlag :nat-code="split.Competitor?.Nationality" tag="td"></FalconFlag>
                                        <AthNameColumn :competitor="split.Competitor"></AthNameColumn>
                                        <td class="text-left">{{ split.Result }}</td>
                                    </tr>
                                </WtcTransitionGroup>
                            </table>
                        </div>
                        <div class="max-tablet:overflow-x-auto">
                            <table v-if="unitTimingData.LapTimes" class="run-results_laps">
                                <thead>
                                    <tr>
                                        <th class="w-12 min-w-12">{{ $getStrByLng().HEADER_LAP }}</th>
                                        <th class="w-28 min-w-28 text-left">{{ $getStrByLng().HEADER_TIME }}</th>
                                    </tr>
                                </thead>
                                <WtcTransitionGroup name="slide" tag="tbody">
                                    <tr v-for="lap in unitTimingData.LapTimes" :key="lap.Lap">
                                        <td>{{ lap.Lap }}</td>
                                        <td class="text-left">{{ lap.Time }}</td>
                                    </tr>
                                </WtcTransitionGroup>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </WtcChannel>
</template>
<script setup lang="ts">
import { FalconFlag, useFalconStore } from "@swisstiming/falcon-core";
import {
    getStrByLng,
    ScheduleStatus,
    usePSBinaryEntry,
    WtcButton,
    WtcChannel,
    WtcImage,
    WtcTransitionGroup
} from "@swisstiming/webtec-kit";
import { storeToRefs } from "pinia";
import Dialog from "primevue/dialog";
import { computed, ref, toRef } from "vue";

import paceImageUrl from "../../assets/images/icon_pacemaker.svg";
import AthResultHeader from "../../components/elements/results/AthResultHeader.vue";
import AthBestColumns from "../../components/elements/tables/AthBestColumns.vue";
import AthBestHeadColumns from "../../components/elements/tables/AthBestHeadColumns.vue";
import AthBibColumn from "../../components/elements/tables/AthBibColumn.vue";
import AthBibHeadColumn from "../../components/elements/tables/AthBibHeadColumn.vue";
import AthCombinedPointsColumns from "../../components/elements/tables/AthCombinedPointsColumns.vue";
import AthCombinedPointsHeadColumns from "../../components/elements/tables/AthCombinedPointsHeadColumns.vue";
import AthComplexResultColumn from "../../components/elements/tables/AthComplexResultColumn.vue";
import AthDiamondRaceColumns from "../../components/elements/tables/AthDiamondRaceColumns.vue";
import AthDiamondRaceHeadColumns from "../../components/elements/tables/AthDiamondRaceHeadColumns.vue";
import AthNameColumn from "../../components/elements/tables/AthNameColumn.vue";
import AthRankColumn from "../../components/elements/tables/AthRankColumn.vue";
import { useUnitTimingChannel } from "../../composables/ath-data.composables";
import { IAthCompetitorDetail } from "../../interfaces/ath.interfaces";
const photoFinishVisible = ref(false);
const { config } = storeToRefs(useFalconStore());

const props = defineProps<{
    unitRsc?: string;
}>();

const { unitTimingData, channelName, isCombinedDiscipline, isDiamondQualiRace, sortedResultList } =
    useUnitTimingChannel(toRef(props, "unitRsc"));
const displaySplitTimes = computed(
    () => unitTimingData.value.Status !== ScheduleStatus.Official && unitTimingData.value.Stats?.LiveSplits
);

const { binaryFileUrl: photoUrl, binaryEntry: photoEntry } = usePSBinaryEntry(
    computed(() => `PHOTOFINISH_${unitTimingData.value.Stats?.OvrId}_JPG`),
    computed(() => config.value.namespace),
    computed(() => config.value.tournamentId)
);

const laneLabel = computed(() =>
    unitTimingData.value.Stats?.StartType === "Lane" ? getStrByLng().HEADER_LANE : getStrByLng().HEADER_START_POS
);

const joinedOfficialSplits = computed(() =>
    unitTimingData.value.OfficialSplits?.map((split) => {
        return {
            ...split,
            Competitor: unitTimingData.value.CompetitorDetails?.[split.Id]
        };
    })
);
</script>

<style scoped lang="scss"></style>
