import { useFalconStore } from "@swisstiming/falcon-core";
import {
    getTimingChannel,
    IMedalTableChannel,
    RscCode,
    useChannel,
    useTimingChannelData
} from "@swisstiming/webtec-kit";
import { storeToRefs } from "pinia";
import { computed, Ref } from "vue";

import { AthDiamondLeaguePhaseTypes } from "../enums/ath.enums";
import { IAthTimingChannel } from "../interfaces/ath.interfaces";
import { useAthStore } from "../stores/ath.store";
import { getDiamondStandingsChannel } from "../utils/ath-channel-names.utils";

export const useAthConfig = () => storeToRefs(useAthStore()).athConfig;
export const useAthChannels = () => storeToRefs(useAthStore()).channelNames;

export const useAthTimingChannel = (rsc: Ref<string>) => {
    const { config } = storeToRefs(useFalconStore());

    const channelName = computed(() => getTimingChannel(config.value.tournamentId, rsc.value));
    const { content: timingData } = useChannel<IAthTimingChannel>(channelName);
    //@ts-ignore
    const { sortedResultList, sortedStartList } = useTimingChannelData(timingData);

    return { channelName, timingData, sortedResultList, sortedStartList };
};

export const useUnitTimingChannel = (rsc: Ref<string>) => {
    const { config } = storeToRefs(useFalconStore());

    const channelName = computed(() => getTimingChannel(config.value.tournamentId, rsc.value));
    const { content: unitTimingData } = useChannel<IAthTimingChannel>(channelName);
    //@ts-ignore
    const { sortedResultList, sortedStartList } = useTimingChannelData(unitTimingData);

    const isCombinedDiscipline = computed(() => unitTimingData.value.Stats?.Combined === "y");
    const isDiamondQualiRace = computed(
        () => unitTimingData.value.Stats?.DiamondType === AthDiamondLeaguePhaseTypes.QUALIFICATION
    );

    const hasWind = computed(() => unitTimingData.value.Stats?.HasWind === "y");

    return {
        unitTimingData,
        sortedResultList,
        sortedStartList,
        isCombinedDiscipline,
        isDiamondQualiRace,
        hasWind,
        channelName
    };
};

export const useDiamondStanding = (diamondId: Ref<string>) => {
    const { config } = storeToRefs(useFalconStore());

    const diamondStandingsChannelName = computed(() =>
        getDiamondStandingsChannel(config.value.tournamentId, diamondId.value)
    );

    const { content: diamondStanding } = useChannel<IMedalTableChannel>(diamondStandingsChannelName);
    return { diamondStanding, diamondStandingsChannelName };
};
