<template>
    <template v-if="athConfig.isPBnSBEnabled">
        <th class="w-16 min-w-16">{{ $getStrByLng().HEADER_SEASONAL_BEST }}</th>
        <th class="border-content/20 w-16 min-w-16 border-r">{{ $getStrByLng().HEADER_PERSONAL_BEST }}</th>
    </template>
</template>
<script setup lang="ts">
import { useAthStore } from "../../../stores/ath.store";

const { athConfig } = useAthStore();
</script>
