<template>
    <div class="wtc__accordion border-b border-b-black/10 py-2">
        <header
            class="flex cursor-pointer items-center justify-between gap-4"
            :class="pt.header"
            @click.stop="handleClick"
        >
            <div class="flex-1 text-base">
                <slot name="title"></slot>
            </div>
            <div class="flex min-w-4 justify-center" :class="pt.icon">
                <font-awesome-icon :icon="relevantExpanded ? iconExpanded : icon" />
            </div>
        </header>
        <WtcTransition :duration-ms="100" :name="animation">
            <section v-if="relevantExpanded" :class="pt.content">
                <slot name="content" />
            </section>
        </WtcTransition>
    </div>
</template>

<script lang="ts" setup>
import { faAngleDown, faAngleUp, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { computed, ref } from "vue";

import WtcTransition, { animationType } from "../appearance/WtcTransition.vue";

interface IWtcAccordionPassThrough {
    header?: string;
    content?: string;
    icon?: string;
}

const props = withDefaults(
    defineProps<{
        icon?: IconDefinition;
        iconExpanded?: IconDefinition;
        expanded?: boolean;
        expandOnClick?: boolean;
        onHeaderClick?: () => void;
        animation?: animationType;
        pt?: IWtcAccordionPassThrough;
    }>(),
    {
        icon: () => faAngleDown,
        iconExpanded: () => faAngleUp,
        expanded: false,
        expandOnClick: true,
        animation: "slide-down",
        pt: (): IWtcAccordionPassThrough => {
            return { header: undefined, content: undefined, icon: undefined };
        }
    }
);

const _expanded = ref(props.expanded);

const relevantExpanded = computed(() => (!props.expandOnClick ? props.expanded : _expanded.value));

const handleClick = () => {
    if (props.expandOnClick) {
        _expanded.value = !_expanded.value;
    }
    props.onHeaderClick?.();
};
</script>

<style lang="scss"></style>
