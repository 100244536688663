export enum AthDisciplineTypes {
    RUN = "RUN",
    WIDTH = "WIDTH",
    COMBINED = "COMBINED",
    HEIGHT = "HEIGHT",
    RELAY = "RELAY",
    ROAD = "ROAD",
    ROAD_TEAM = "ROAD_TEAM",
    NON_EVENT = "NONEVENT",
}
