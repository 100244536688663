import "./styles/styles.scss";

import { falconPlugin } from "@swisstiming/falcon-core";
import { passThroughTransitionSlideDown, SportCodes } from "@swisstiming/webtec-kit";
import PrimeVue, { PrimeVueConfiguration } from "primevue/config";
import { createApp } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";

import App from "./App.vue";
import { routes } from "./routes";

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

const app = createApp(App)
    .use(falconPlugin, {
        title: "Athletics Web Results",
        router,
        storeOptions: {
            sportCode: SportCodes.ATHLETICS,
            initOptions: {
                withCurrentEvent: true,
                withCisConfig: true,
                withSeason: false
            },
            iframeIntegration: {
                required: true,
                domains: [
                    "leichtathletik.de",
                    "www.weltklassezuerich.ch",
                    "istaf.de",
                    "istaf-indoor.de",
                    "duesseldorf.istaf-indoor.de",
                    "fbkgames.nl",
                    "diamondleague.com",
                    "meeting-goetzis.at",
                    "meeting-karlsruhe.de",
                    "omegatiming.com",
                    "falcon-monitor.liveresults.uat-sports.swisstiming.com"
                ]
            }
        }
    })
    .use<PrimeVueConfiguration>(PrimeVue, {
        unstyled: true,
        pt: {
            multiselect: {
                transition: passThroughTransitionSlideDown
            },
            select: {
                clearIcon: "absolute top-1/2 -mt-2 inline-block end-10 w-4 h-4 text-content/50",
                label: "flex-auto overflow-hidden w-[1%] whitespace-nowrap text-ellipsis cursor-pointer block outline-none px-2 py-1 pe-6",
                dropdown: "w-10 flex items-center justify-center flex-shrink-0",
                root: "border-content border bg-white cursor-pointer select-none w-56 relative inline-flex",
                overlay: "bg-white border border-black/50",
                option: "cursor-pointer px-2 py-1 hover:bg-brand hover:text-white text-sm",
                listContainer: "overflow-auto",
                transition: passThroughTransitionSlideDown
            }
        }
    });

app.mount("#app");
