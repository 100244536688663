<template>
    <ul
        class="records text-content/60 grid grid-flow-row auto-rows-max grid-cols-2 place-items-center gap-x-1 text-[0.6rem]"
    >
        <li v-for="record in recordsToRender" :key="record">
            {{ record }}
        </li>
    </ul>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";

import { IAthCompetitorDetail } from "../../../interfaces/ath.interfaces";

const props = defineProps({
    competitor: {
        type: Object as PropType<IAthCompetitorDetail>,
        default: () => {
            return {};
        }
    }
});

const recordsToRender = computed(() =>
    [...(props.competitor.RecordsMaj ?? []), ...(props.competitor.RecordsMin ?? [])].slice(0, 4)
);
</script>

<style scoped lang="scss"></style>
