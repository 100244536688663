<template>
    <div class="ath__diamond-standing-page">
        <WtcChannel :channel-name="diamondStandingsChannelName" use-transition>
            <template #slot__result>
                <div>
                    <div v-if="eventsFinished" class="font-normal_medium mb-4 text-sm">{{ eventsFinished }}</div>
                    <table class="w-full">
                        <thead>
                            <tr>
                                <!-- Nat -->
                                <th class="w-16 min-w-16">{{ $getStrByLng().HEADER_NAT }}</th>
                                <!-- Name -->
                                <th class="text-left">{{ $getStrByLng().HEADER_NAME }}</th>
                                <!-- Points -->
                                <th class="w-16 min-w-16">{{ $getStrByLng().HEADER_POINTS_RESULT }}</th>
                                <!-- Ratio -->
                                <th class="w-48 min-w-48 text-left">{{ $getStrByLng().HEADER_PERFORMANCE }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="competitor in sortedCompsNRatios" :key="competitor.Id">
                                <FalconFlag :nat-code="competitor.Nationality" tag="td"></FalconFlag>
                                <AthNameColumn :competitor="competitor" use-team-member></AthNameColumn>
                                <td class="points">
                                    <span class="pts-value">{{ competitor.Result }}</span>
                                    <span class="qualified">{{ competitor.QualificationMark }}</span>
                                </td>
                                <td class="ratio">
                                    <ProgressBar
                                        :pt="{
                                            root: 'relative overflow-hidden rounded-md bg-black/15',
                                            value: 'bg-brand-secondary transition-width duration-1000 ease-in-out h-3'
                                        }"
                                        :show-value="false"
                                        :value="competitor.PtsPercentValue"
                                    ></ProgressBar>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </WtcChannel>
    </div>
</template>
<script setup lang="ts">
import { FalconFlag } from "@swisstiming/falcon-core";
import { getStrByLng, objectToSortedArray, WtcChannel } from "@swisstiming/webtec-kit";
import ProgressBar from "primevue/progressbar";
import { computed, toRef } from "vue";

import AthNameColumn from "../../components/elements/tables/AthNameColumn.vue";
import { useDiamondStanding } from "../../composables/ath-data.composables";
const props = defineProps<{
    diamondId: string;
}>();

const { diamondStanding, diamondStandingsChannelName } = useDiamondStanding(toRef(props, "diamondId"));

const eventsFinished = computed(() => {
    const finished = diamondStanding.value.Stats?.Finished;
    const total = diamondStanding.value.Stats?.Total;

    return finished && total
        ? getStrByLng()
              .MESSAGE_DIAMOND_QUALIFICATION.replace("###FINISHED###", diamondStanding.value.Stats.Finished)
              .replace("###TOTAL###", total)
        : undefined;
});

const sortedComps = computed(() => objectToSortedArray(diamondStanding.value.Competitors, "ListIndex"));

const maxValueOfPoints = computed(() => {
    const points = sortedComps.value.map((item) => (item.Result ? +item.Result : 0));
    return Math.max(...points);
});

const sortedCompsNRatios = computed(() =>
    sortedComps.value.map((comp) => {
        return {
            ...comp,
            PtsPercentValue:
                comp.Result && maxValueOfPoints.value > 0 ? (+comp.Result / maxValueOfPoints.value) * 100 : 0
        };
    })
);
</script>

<style scoped lang="scss"></style>
