<template>
    <td v-if="athConfig.isBibEnabled" class="bib">
        <AthCompetitorCard :card="competitor?.Stats?.Card_Start"></AthCompetitorCard>
        {{ competitor?.Bib }}
    </td>
</template>
<script setup lang="ts">
import { IAthCompetitorDetail } from "../../../interfaces/ath.interfaces";
import { useAthStore } from "../../../stores/ath.store";
import AthCompetitorCard from "../results/AthCompetitorCard.vue";

defineProps<{
    competitor?: IAthCompetitorDetail;
}>();

const { athConfig } = useAthStore();
</script>
