<template>
    <WtcChannel :channel-name="recordsChannelName" use-transition>
        <template #slot__result>
            <div class="flex gap-2">
                <ul v-for="record in resolvedRecords" :key="record.Code" class="bg-surface-alternate min-w-72 p-2">
                    <li class="record-item">
                        <div class="text-brand-secondary flex items-center justify-between text-sm">
                            <span>{{ useTranslatedProperty(record, "Name") }}</span>
                            <span class="font-normal_bold text-base">{{ record.Result }}</span>
                        </div>
                        <div class="mt-2">
                            <div class="flex items-center">
                                <FalconFlag v-if="record.Nat" class="text-xs" :nat-code="record.Nat"></FalconFlag>
                                <span class="font-normal_medium ml-1 text-sm">{{ record.Athlete }}</span>
                            </div>
                            <div class="text-content/40 flex text-[0.8em]">
                                <div class="location">{{ record.Location }}</div>
                                <WtcDateTime :date="record.Date" format="DD. MMM YYYY"></WtcDateTime>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </template>
    </WtcChannel>
</template>

<script setup lang="ts">
import { FalconFlag, useFalconStore, useTranslatedProperty } from "@swisstiming/falcon-core";
import { getCompRecordsChannel, useChannel, WtcChannel, WtcDateTime } from "@swisstiming/webtec-kit";
import { storeToRefs } from "pinia";
import { computed, PropType } from "vue";

import { IATHRecord, IAthRecordsChannel } from "../../../interfaces/ath.interfaces";
const props = defineProps({
    records: Array as PropType<IATHRecord[]>,
    recCode: String
});

const { config } = storeToRefs(useFalconStore());

const recordsChannelName = computed(() => getCompRecordsChannel(config.value.tournamentId, props.recCode));
const { content: recordsChan } = useChannel<IAthRecordsChannel>(recordsChannelName);

const resolvedRecords = computed(() =>
    props.records
        ?.filter((recordEntry) => recordsChan.value.RecordDetails?.[recordEntry.Value] !== undefined)
        ?.map((recordEntry) => {
            return {
                ...recordsChan.value.RecordDetails?.[recordEntry.Value],
                Code: recordEntry?.Code
            };
        })
);
</script>

<style scoped lang="scss"></style>
