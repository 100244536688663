import { useDateFormat } from "@vueuse/core";
import { isNil, sortBy } from "lodash-es";
import { computed, ComputedRef, Ref } from "vue";

import { CommonCompTypes, CommonStatTypes } from "../enums/channel.enums";
import { IScheduleChannel, IScheduleDiscipline, ISchedulePhase } from "../interfaces/channel/schedule";
import { addLeadingZero } from "../utils/string.utils";
import { useDateTrio } from "../utils/time.utils";
import { useChannel } from "./channel.composables";

export const useScheduleData = (rawData: Ref<IScheduleChannel>) => {
    const { tomorrow, today } = useDateTrio();

    const locations = computed(() => rawData.value.ListLocation ?? []);

    const sortedUnits = computed(() =>
        sortBy(
            Object.values(rawData.value.Units ?? {}).filter(
                (x) => x.Stats.CompType !== CommonCompTypes.MEDAL && x.Stats.Type !== CommonStatTypes.NON_EVENT
            ),
            (x) => x.Indexes.All.All
        )
    );
    const sortedDisciplines: ComputedRef<IScheduleDiscipline[]> = computed(() =>
        sortBy(
            Object.values(rawData.value.ListEvent ?? {}).filter((x) => !x.Code.includes("NON")),
            (x) => x.ListIndex
        )
    );

    const sortedPhases: ComputedRef<ISchedulePhase[]> = computed(() =>
        rawData.value.ListPhase?.filter((phase) => !isNil(phase.Name))
    );

    const indexedDays = computed(
        () =>
            rawData.value?.ListDay?.map((x, index) => {
                return { dayIndex: addLeadingZero(index + 1), dayValue: x };
            }) // YYYY-MM-DD
    );
    const unitsGroupedByDay = computed(() => Object.groupBy(sortedUnits.value, ({ Date }) => Date));
    const todayFormatted = useDateFormat(today, "YYYY-MM-DD");
    const tomorrowFormatted = useDateFormat(tomorrow, "YYYY-MM-DD");

    const todaysCompetitionDay = computed(() => rawData.value.ListDay?.find((day) => day === todayFormatted.value));
    const tomorrowsCompetitionDay = computed(() =>
        rawData.value.ListDay?.find((day) => day === tomorrowFormatted.value)
    );

    return {
        sortedUnits,
        unitsGroupedByDay,
        sortedDisciplines,
        sortedPhases,
        locations,
        indexedDays,
        todaysCompetitionDay,
        tomorrowsCompetitionDay,
        rawData
    };
};

export const useScheduleChannel = (scheduleChannelName: Ref<string>) =>
    useScheduleData(useChannel<IScheduleChannel>(scheduleChannelName).content);
