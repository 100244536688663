<template>
    <WtcChannel :channel-name="channelName">
        <template #slot__result>
            <table class="w-full">
                <thead>
                    <tr>
                        <th class="w-14 min-w-14">{{ $getStrByLng().HEADER_RANK }}</th>
                        <AthBibHeadColumn class="w-14 min-w-14"></AthBibHeadColumn>
                        <th class="w-20 min-w-20 text-left">{{ $getStrByLng().HEADER_NAT }}</th>
                        <th class="text-left">{{ $getStrByLng().HEADER_NAME }}</th>
                        <th class="w-16 min-w-16 text-left">{{ $getStrByLng().HEADER_TIME }}</th>
                    </tr>
                </thead>
                <WtcTransitionGroup name="list-sort" tag="tbody">
                    <tr v-for="competitor in sortedResultList as IAthCompetitorDetail[]" :key="competitor.Id">
                        <AthRankColumn :competitor></AthRankColumn>
                        <AthBibColumn :competitor></AthBibColumn>
                        <FalconFlag :nat-code="competitor.Nationality" tag="td"></FalconFlag>
                        <AthNameColumn :competitor use-team-member></AthNameColumn>

                        <!-- Time -->
                        <AthComplexResultColumn
                            :class="{
                                '!bg-surface-highlightLight': competitor.Stats?.Live_LeaderLap !== undefined
                            }"
                            :competitor
                        >
                            <!-- All the run specific additions being displayed along with the time -->
                            <img v-if="competitor.Stats?.Pace" />
                            <span v-if="displaySplitTimes" class="current-split">{{
                                competitor.Stats?.Live_Split
                            }}</span>
                        </AthComplexResultColumn>
                    </tr>
                </WtcTransitionGroup>
            </table>
        </template>
    </WtcChannel>
</template>
<script setup lang="ts">
import { FalconFlag } from "@swisstiming/falcon-core";
import { ScheduleStatus, WtcChannel, WtcTransitionGroup } from "@swisstiming/webtec-kit";
import { computed, toRef } from "vue";

import { useAthTimingChannel } from "../../../composables/ath-data.composables";
import { IAthCompetitorDetail } from "../../../interfaces/ath.interfaces";
import AthBibColumn from "../tables/AthBibColumn.vue";
import AthBibHeadColumn from "../tables/AthBibHeadColumn.vue";
import AthComplexResultColumn from "../tables/AthComplexResultColumn.vue";
import AthNameColumn from "../tables/AthNameColumn.vue";
import AthRankColumn from "../tables/AthRankColumn.vue";

const props = defineProps<{ rsc: string }>();

const { sortedResultList, timingData, channelName } = useAthTimingChannel(toRef(props, "rsc"));

const displaySplitTimes = computed(
    () => timingData.value.Status !== ScheduleStatus.Official && timingData.value.Stats?.LiveSplits
);
</script>

<style scoped lang="scss"></style>
