<template>
    <component :is="isString(href) ? 'a' : 'button'" class="wtc__button flex items-center" :class="pt?.content" :href>
        <div v-if="icon" class="mr-1" :class="pt?.icon"><font-awesome-icon :icon="icon" /></div>
        <div v-if="text || slots.default" :class="pt?.label">
            <slot>{{ text }}</slot>
        </div>
    </component>
</template>

<script lang="ts" setup>
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { isString } from "lodash-es";

interface IWtcButtonPassThrough {
    content?: string;
    icon?: string;
    label?: string;
}

defineProps<{
    icon?: IconDefinition;
    text?: string;
    href?: string;
    pt?: IWtcButtonPassThrough;
}>();

const slots = defineSlots<{
    default;
}>();
</script>
