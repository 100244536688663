<template>
    <div>
        <header>
            <nav>
                <ul
                    v-if="phasesOfEvent && phasesOfEvent.length > 1"
                    class="bg-surface-alternate max-tablet:flex-wrap mb-4 flex p-1 text-sm leading-none"
                >
                    <li v-for="phase in phasesOfEvent" :key="phase.Rsc">
                        <AthRouterLink
                            :params="{
                                phaseRsc: phase.Rsc,
                                unitRsc: phase.FirstUnit?.Rsc
                            }"
                            :pt="{
                                content:
                                    phase.Rsc === phaseRsc ? 'bg-brand text-white block p-2' : 'text-brand p-2 block'
                            }"
                            :route-label="useTranslatedProperty(phase, 'Name') ?? phase.Round"
                            :route-name="ATH_ROUTE_NAMES.RESULT_DETAILS"
                            tag="div"
                        >
                        </AthRouterLink>
                    </li>
                </ul>

                <div v-if="unitsOfPhase && unitsOfPhase.length > 1">
                    <ul class="border-brand max-tablet:flex-wrap mb-2 flex gap-2 border-b text-sm">
                        <li v-for="unit in unitsOfPhase" :key="unit.Rsc">
                            <AthRouterLink
                                :params="{
                                    unitRsc: unit.Rsc
                                }"
                                :pt="{
                                    active: 'bg-brand text-white',
                                    content: 'flex items-center px-2 py-1'
                                }"
                                :route-label="useTranslatedProperty(unit, 'Name') || $getStrByLng().LABEL_RESULTS"
                                :route-name="ATH_ROUTE_NAMES.RESULT_DETAILS"
                                tag="div"
                            >
                            </AthRouterLink>
                        </li>

                        <!-- Phase has a summary. -->
                        <li v-if="phase.Stats?.IsCombined">
                            <AthRouterLink
                                :params="{
                                    phaseRsc
                                }"
                                :pt="{
                                    active: 'bg-brand text-white',
                                    content: 'flex items-center px-2 py-1'
                                }"
                                :route-label="$getStrByLng().NAVIGATION_SUMMARY"
                                :route-name="ATH_ROUTE_NAMES.SUMMARY"
                            >
                            </AthRouterLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
        <router-view></router-view>
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import Popover from "primevue/popover";
import { computed, ref } from "vue";

import diamondImageUrl from "../assets/images/icon_diamond_large.png";
import AthRouterLink from "../components/elements/AthRouterLink.vue";
import { ATH_ROUTE_NAMES } from "../enums/ath.enums";
import { useAthStore } from "../stores/ath.store";

const props = defineProps({
    phaseRsc: {
        type: String,
        required: true
    },
    unitRsc: {
        type: String,
        required: true
    }
});
const { channelNames } = storeToRefs(useFalconStore());

import { useFalconStore, useTranslatedProperty } from "@swisstiming/falcon-core";
import {
    getCombinedValue,
    objectToSortedArray,
    RscCode,
    useCompStrucChannel,
    useScheduleChannel,
    WtcImage
} from "@swisstiming/webtec-kit";

const popoverRef = ref();
const { athConfig } = storeToRefs(useAthStore());

const { event, phase } = useCompStrucChannel(computed(() => channelNames.value.compStructure)).getNodeEntries(
    computed(() => new RscCode(props.unitRsc))
);

const { sortedUnits } = useScheduleChannel(computed(() => channelNames.value.schedule));

const phasesOfEvent = computed(() =>
    objectToSortedArray(event.value?.Phases, "ListIndex").map((phase) => {
        const sortedUnits = objectToSortedArray(phase.Units);
        return {
            ...phase,
            SortedUnits: objectToSortedArray(phase.Units),
            FirstUnit: sortedUnits?.[0]
        };
    })
);
const unitsOfPhase = computed(() => objectToSortedArray(phase.value?.Units, "ListIndex"));
</script>
