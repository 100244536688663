<template>
    <WtcChannel :channel-name="channelNames.compStructure" use-transition>
        <template #slot__result>
            <div>
                <div class="flex items-center justify-between">
                    <div class="font-normal_medium text-2xl">{{ $getStrByLng().LABEL_PDF_SECTION_GLOBALS }}</div>

                    <ul class="flex gap-4 text-sm">
                        <li v-for="pdf in sortedPdfs" :key="pdf.Code" class="text-brand-secondary hover:underline">
                            <FontAwesomeIcon class="mr-1 text-sm" :icon="faFilePdf"></FontAwesomeIcon>
                            <AthPdfHyperLink
                                :binary-name="pdf.FileName"
                                :title="pdf.Title ?? pdf.Code"
                            ></AthPdfHyperLink>
                        </li>
                    </ul>
                </div>

                <div>
                    <ul class="mt-2 grid grid-cols-2 gap-2">
                        <li v-for="discipline in competitionsNPdfIndication" :key="discipline.Rsc">
                            <WtcAccordion class="bg-surface-alternate border-b-0 px-2">
                                <template #title>
                                    <div class="bg-surface-alternate flex gap-3">
                                        <span>
                                            {{ useTranslatedProperty(discipline, "Name") }}
                                        </span>
                                        <AthPdfList :pdfs="discipline.Pdfs"></AthPdfList>
                                    </div>
                                </template>
                                <template #content>
                                    <div class="mt-2">
                                        <ul class="grid grid-cols-4 gap-4">
                                            <li
                                                v-for="phase in discipline.Phases"
                                                :key="phase.Rsc"
                                                class="bg-surface items-center p-2"
                                            >
                                                <div class="mb-2 flex items-center justify-between gap-4">
                                                    <div class="text-sm">
                                                        {{
                                                            useTranslatedProperty(phase, "Name") ||
                                                            useTranslatedProperty(phase, "Round")
                                                        }}
                                                    </div>
                                                    <div class="text-sm">
                                                        <AthPdfList :pdfs="phase.Pdfs"></AthPdfList>
                                                    </div>
                                                </div>

                                                <ul class="text-content/60 text-xs">
                                                    <li
                                                        v-for="unit in phase.Units"
                                                        :key="unit.Rsc"
                                                        class="flex justify-between gap-4 border-t border-t-black/25 py-1 first:border-t-0"
                                                    >
                                                        <span> {{ useTranslatedProperty(unit, "Name") }}</span>
                                                        <AthPdfList :pdfs="unit.Pdfs"></AthPdfList>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </template>
                            </WtcAccordion>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </WtcChannel>
</template>
<script setup lang="ts">
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useFalconStore, useTranslatedProperty } from "@swisstiming/falcon-core";
import { useCompStrucChannel, WtcAccordion, WtcChannel } from "@swisstiming/webtec-kit";
import { storeToRefs } from "pinia";
import { computed } from "vue";

import AthPdfHyperLink from "../../components/elements/pdfs/AthPdfHyperLink.vue";
import AthPdfList from "../../components/elements/pdfs/AthPdfList.vue";
const { channelNames } = storeToRefs(useFalconStore());
const { sortedPdfs, allCompetitionsSorted } = useCompStrucChannel(computed(() => channelNames.value.compStructure));

const competitionsNPdfIndication = computed(() =>
    allCompetitionsSorted.value.map((event) => {
        return {
            ...event,
            hasAnyPdf: event.Phases.some(
                (phase) =>
                    Object.keys(phase.Pdfs ?? {}).length > 0 ||
                    phase.Units.some((unit) => Object.keys(unit.Pdfs ?? {}).length > 0)
            )
        };
    })
);
</script>

<style scoped lang="scss"></style>
