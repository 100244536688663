<template>
    <component :is="tag" class="falcon__status-batch text-right">
        <div
            v-if="batchText"
            class="font-weight-medium text-content align-center justify-center whitespace-nowrap p-1 text-xs"
            :class="{
                'text-content/50': ScheduleStatus.Unofficial === statusCode,
                'text-surface-comingup':
                    ScheduleStatus.Planned === statusCode ||
                    ScheduleStatus.GettingReady === statusCode ||
                    ScheduleStatus.ComingUp === statusCode ||
                    ScheduleStatus.Delayed === statusCode ||
                    ScheduleStatus.Rescheduled === statusCode ||
                    ScheduleStatus.Postponed === statusCode ||
                    ScheduleStatus.FinalJudgeCheck === statusCode,
                'text-surface-running':
                    ScheduleStatus.Running === statusCode ||
                    ScheduleStatus.Break === statusCode ||
                    ScheduleStatus.Cancelled === statusCode ||
                    ScheduleStatus.Protested === statusCode ||
                    ScheduleStatus.Interrupted === statusCode,
                'text-surface-finished':
                    ScheduleStatus.Finished === statusCode || ScheduleStatus.Official === statusCode
            }"
        >
            {{ batchText }}
        </div>
    </component>
</template>

<script lang="ts" setup>
import { mapScheduleStatusToText, ScheduleStatus } from "@swisstiming/webtec-kit";
import { computed, PropType } from "vue";

const props = defineProps({
    tag: {
        type: String,
        default: "div"
    },
    statusCode: {
        type: String as PropType<ScheduleStatus>
    }
});

const batchText = computed(() => mapScheduleStatusToText(props.statusCode));
</script>
