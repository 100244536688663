<template>
    <div class="mt-4">
        <div class="font-normal_medium mb-1">{{ caption }}</div>
        <table class="w-full">
            <thead>
                <tr>
                    <!-- Record Name -->
                    <th v-if="displayRecordName" class="w-48 min-w-48 text-left"></th>
                    <!-- Nat -->
                    <th class="w-16 min-w-16">{{ $getStrByLng().HEADER_NAT }}</th>
                    <!-- Name -->
                    <th class="text-left">{{ $getStrByLng().HEADER_NAME }}</th>
                    <!-- Result -->
                    <th class="w-16 min-w-16 text-left">{{ $getStrByLng().HEADER_RESULT }}</th>
                    <!-- Venue -->
                    <th class="w-48 min-w-48 text-left">{{ $getStrByLng().HEADER_VENUE }}</th>
                    <!-- Date -->
                    <th class="w-28 min-w-28 text-left">{{ $getStrByLng().HEADER_DATE }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="recordEntry in records" :key="recordEntry.Code">
                    <td v-if="displayRecordName" class="text-left">
                        <div class="flex items-center">
                            <!-- Record Name -->
                            <span class="record-type">{{ useTranslatedProperty(recordEntry, "Name") }}</span>
                            <!-- Region -->
                            <span class="text-xs">{{ getRegionStrByCode(recordEntry.Region) }}</span>
                        </div>
                    </td>
                    <!-- Nat -->
                    <td>
                        <FalconFlag :nat-code="recordEntry.Nat"></FalconFlag>
                    </td>
                    <!-- Name -->
                    <td class="text-left">
                        <WtcName :competitor-name="{ Name: recordEntry.Athlete }"></WtcName>
                    </td>
                    <!-- Result -->
                    <td class="font-normal_bold text-left">{{ recordEntry.Result }}</td>
                    <!-- Venue -->
                    <td class="text-left">{{ recordEntry.Location }}</td>
                    <!-- Date -->
                    <td class="text-left">
                        <WtcDateTime :date="recordEntry.Date" format="DD. MMM YYYY"></WtcDateTime>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script setup lang="ts">
import { FalconFlag, useTranslatedProperty } from "@swisstiming/falcon-core";
import { getStrByLng, WtcDateTime, WtcName } from "@swisstiming/webtec-kit";
import { boolean } from "mathjs";
import { PropType } from "vue";

import { IATHRecord, IAthRecordDetail } from "../../../interfaces/ath.interfaces";

defineProps({
    caption: String,
    records: {
        type: Array as PropType<(IATHRecord & IAthRecordDetail)[]>,
        default: () => []
    },
    displayRecordName: {
        type: Boolean,
        default: true
    }
});

const getRegionStrByCode = (regionCode: string) => {
    switch (regionCode) {
        case "AF":
            return getStrByLng().LABEL_CONTINENT_AFRICA;
        case "AS":
            return getStrByLng().LABEL_CONTINENT_ASIA;
        case "EU":
            return getStrByLng().LABEL_CONTINENT_EUROPE;
        case "NA":
            return getStrByLng().LABEL_CONTINENT_NORTH_AMERICA;
        case "OC":
            return getStrByLng().LABEL_CONTINENT_OCEANIA;
        case "SA":
            return getStrByLng().LABEL_CONTINENT_SOUTH_AMERICA;
    }
};
</script>
