<template>
    <template v-if="hasPdfsToDisplay">
        <WtcButton :text="$getStrByLng().NAVIGATION_PDF" @click="toggle"></WtcButton>
        <Popover ref="popoverRef">
            <div class="border border-black/50 bg-white px-3 py-1">
                <AthPdfList
                    v-if="withEvent"
                    :pdfs="event?.Pdfs"
                    :pt="pdfPT"
                    :title="$getStrByLng().LABEL_PDF_EVENT_RELATED"
                ></AthPdfList>
                <AthPdfList
                    v-if="withPhase"
                    :pdfs="phase?.Pdfs"
                    :pt="pdfPT"
                    :title="$getStrByLng().LABEL_PDF_PHASE_RELATED"
                ></AthPdfList>
                <AthPdfList
                    v-if="withUnit"
                    :pdfs="unit?.Pdfs"
                    :pt="pdfPT"
                    :title="$getStrByLng().LABEL_PDF_UNIT_RELATED"
                ></AthPdfList>
            </div>
        </Popover>
    </template>
</template>
<script setup lang="ts">
import { useFalconStore } from "@swisstiming/falcon-core";
import { RscCode, useCompStrucChannel, WtcButton } from "@swisstiming/webtec-kit";
import { storeToRefs } from "pinia";
import Popover from "primevue/popover";
import { computed, ref } from "vue";

import AthPdfList from "./AthPdfList.vue";

const props = defineProps({
    rsc: String,
    withEvent: {
        type: Boolean,
        default: false
    },
    withPhase: {
        type: Boolean,
        default: false
    },
    withUnit: {
        type: Boolean,
        default: false
    }
});
const { channelNames } = storeToRefs(useFalconStore());
const pdfPT = {
    list: "flex-col gap-0 text-sm pl-2",
    header: "text-xs",
    content: "mb-1",
    item: "hover:underline"
};

const { event, phase, unit } = useCompStrucChannel(computed(() => channelNames.value.compStructure)).getNodeEntries(
    computed(() => new RscCode(props.rsc))
);

const hasPdfsToDisplay = computed(
    () =>
        (props.withEvent && event.value?.Pdfs) ||
        (props.withPhase && phase.value?.Pdfs) ||
        (props.withUnit && unit.value?.Pdfs)
);

const popoverRef = ref();
const toggle = (event) => {
    popoverRef.value.toggle(event);
};
</script>

<style scoped lang="scss"></style>
