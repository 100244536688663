<template>
    <WtcChannel :channel-name="channelName" use-transition>
        <template #slot__result>
            <div class="ath__long-jump">
                <AthResultHeader :rsc="unitRsc"></AthResultHeader>
                <div class="max-tablet:overflow-x-auto">
                    <table class="w-full">
                        <thead>
                            <tr>
                                <th class="sticky left-0 w-10 min-w-10">{{ $getStrByLng().HEADER_RANK }}</th>
                                <th class="w-10 min-w-10">{{ $getStrByLng().HEADER_START_POS }}</th>
                                <AthBibHeadColumn class="w-10 min-w-10"></AthBibHeadColumn>
                                <th class="w-16 min-w-16">{{ $getStrByLng().HEADER_NAT }}</th>
                                <th class="text-left">{{ $getStrByLng().HEADER_NAME }}</th>
                                <th class="w-24 min-w-24">{{ $getStrByLng().HEADER_BEST }}</th>

                                <AthDiamondRaceHeadColumns v-if="isDiamondQualiRace"></AthDiamondRaceHeadColumns>
                                <AthCombinedPointsHeadColumns
                                    v-if="isCombinedDiscipline"
                                ></AthCombinedPointsHeadColumns>

                                <th v-for="attempt in unitTimingData.Splits" :key="attempt.Id" class="w-16 min-w-16">
                                    <div class="mr-1">{{ attempt.Name }}</div>
                                    <div v-if="hasWind" class="mt-1 text-[0.8em]">{{ $getStrByLng().HEADER_WIND }}</div>
                                </th>
                                <AthBestHeadColumns></AthBestHeadColumns>
                            </tr>
                        </thead>
                        <WtcTransitionGroup name="list-sort" tag="tbody">
                            <tr
                                v-for="competitor in sortedResultList as IAthCompetitorDetail[]"
                                :key="competitor.Id"
                                :class="{
                                    '!border-content/40 !border-b-2': competitor.Stats?.IsLastOfFinal3 === 'True'
                                }"
                            >
                                <FalconRankIrm class="sticky left-0" :competitor></FalconRankIrm>
                                <td>{{ competitor.StartPos }}</td>
                                <AthBibColumn :competitor></AthBibColumn>
                                <FalconFlag :nat-code="competitor.Nationality" tag="td"></FalconFlag>
                                <AthNameColumn :competitor></AthNameColumn>
                                <AthComplexResultColumn :competitor></AthComplexResultColumn>

                                <AthDiamondRaceColumns
                                    v-if="isDiamondQualiRace"
                                    :competitor
                                    :diamond-id="unitTimingData.Stats?.DiamondId"
                                ></AthDiamondRaceColumns>

                                <AthCombinedPointsColumns
                                    v-if="isCombinedDiscipline"
                                    :competitor="competitor as IAthCompetitorDetail"
                                ></AthCombinedPointsColumns>

                                <td
                                    v-for="(attempt, index) in competitor.Intermediate"
                                    :key="attempt.Number"
                                    v-highlight="attempt.Result"
                                    :class="{
                                        'best-attempt': attempt.Flag !== undefined,
                                        'current-attempt': attempt.Current !== undefined,
                                        'border-content border-r': separators?.includes(index + 1)
                                    }"
                                >
                                    <div class="flex flex-col">
                                        <span>{{ attempt.Result }}</span>
                                        <span v-if="hasWind" class="mt-1 text-[0.9em] opacity-70">{{
                                            attempt.Wind
                                        }}</span>
                                    </div>
                                </td>
                                <AthBestColumns :competitor="competitor as IAthCompetitorDetail"></AthBestColumns>
                            </tr>
                        </WtcTransitionGroup>
                    </table>
                </div>
            </div>
        </template>
    </WtcChannel>
</template>
<script setup lang="ts">
import { FalconFlag, FalconRankIrm } from "@swisstiming/falcon-core";
import { WtcChannel, WtcTransitionGroup } from "@swisstiming/webtec-kit";
import { computed, toRef } from "vue";

import AthResultHeader from "../../components/elements/results/AthResultHeader.vue";
import AthBestColumns from "../../components/elements/tables/AthBestColumns.vue";
import AthBestHeadColumns from "../../components/elements/tables/AthBestHeadColumns.vue";
import AthBibColumn from "../../components/elements/tables/AthBibColumn.vue";
import AthBibHeadColumn from "../../components/elements/tables/AthBibHeadColumn.vue";
import AthCombinedPointsColumns from "../../components/elements/tables/AthCombinedPointsColumns.vue";
import AthCombinedPointsHeadColumns from "../../components/elements/tables/AthCombinedPointsHeadColumns.vue";
import AthComplexResultColumn from "../../components/elements/tables/AthComplexResultColumn.vue";
import AthDiamondRaceColumns from "../../components/elements/tables/AthDiamondRaceColumns.vue";
import AthDiamondRaceHeadColumns from "../../components/elements/tables/AthDiamondRaceHeadColumns.vue";
import AthNameColumn from "../../components/elements/tables/AthNameColumn.vue";
import { useUnitTimingChannel } from "../../composables/ath-data.composables";
import { IAthCompetitorDetail } from "../../interfaces/ath.interfaces";

const props = defineProps<{
    unitRsc?: string;
}>();

const { unitTimingData, channelName, isCombinedDiscipline, isDiamondQualiRace, hasWind, sortedResultList } =
    useUnitTimingChannel(toRef(props, "unitRsc"));

const separators = computed(() => unitTimingData.value.AttemptSeparators);
</script>

<style scoped lang="scss"></style>
