<template>
    <img v-if="targetUrl" alt="" :src="targetUrl" />
</template>
<script setup lang="ts">
import cardRed from "@swisstiming/webtec-assets/icons/card_red.svg";
import cardYellowRed from "@swisstiming/webtec-assets/icons/card_red_yellow.svg";
import cardYellow from "@swisstiming/webtec-assets/icons/card_yellow.svg";
import { computed } from "vue";

const props = defineProps<{
    card?: "YC" | "RC" | "YRC";
}>();

const targetUrl = computed(() => {
    switch (props.card) {
        case "RC":
            return cardRed;
        case "YRC":
            return cardYellowRed;
        case "YC":
            return cardYellow;
    }
    return undefined;
});
</script>

<style scoped lang="scss"></style>
