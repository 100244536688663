<template>
    <MultiSelect
        ref="multiSelectEl"
        class="max-mobile:w-full"
        :emptyMessage
        option-group-label="label"
        :options
        overlay-class="custom-multi-select-overlay"
        :pt
        :scrollHeight
        :show-toggle-all="false"
    >
        <template #value>
            <slot name="value">{{ selectedOptionsDisplayValue }}</slot>
        </template>
        <template #optiongroup="{ option, index }">
            <div class="max-tablet:gap-0 flex flex-col gap-5">
                <div
                    class="font-normal_bold flex cursor-pointer select-none items-center justify-between gap-2 bg-white leading-none"
                >
                    <div class="flex gap-2" @click="toggleGroupSelect(option.items)">
                        <slot
                            :allSelected="isEveryItemGroupSelected(option.items)"
                            name="group-icon"
                            :someSelected="isItemGroupSelected(option.items)"
                        >
                            <font-awesome-icon
                                :icon="
                                    isEveryItemGroupSelected(option.items)
                                        ? faSquareCheck
                                        : isItemGroupSelected(option.items)
                                          ? faSquareMinus
                                          : faSquare
                                "
                            />
                        </slot>
                        <span>{{ option.label }}</span>
                    </div>

                    <font-awesome-icon
                        v-if="expandableOptions"
                        class="tablet:hidden"
                        :icon="isOptionGroupOpen(index) ? faChevronUp : faChevronDown"
                        @click="toggleOptionGroup(index)"
                    />
                </div>
                <div class="max-laptop:max-h-40 max-laptop:overflow-y-auto">
                    <ul
                        class="max-tablet:mt-4 grid grid-flow-col grid-rows-6 gap-x-8"
                        :class="[pt.wtcList, { 'max-tablet:hidden': expandableOptions && !isOptionGroupOpen(index) }]"
                    >
                        <li
                            v-for="item in option.items"
                            :key="item.value"
                            class="flex h-8 cursor-pointer select-none items-center gap-2"
                            @click="toggleItemSelect(item)"
                        >
                            <slot :isSelected="isItemSelected(item)" name="item-icon">
                                <font-awesome-icon :icon="isItemSelected(item) ? faSquareCheck : faSquare" />
                            </slot>
                            <span class="leading-none">{{ item.label }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </MultiSelect>
</template>

<script setup lang="ts">
import { faSquare, faSquareCheck, faSquareMinus } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import MultiSelect, { MultiSelectPassThroughOptions } from "primevue/multiselect";
import { computed, onMounted, ref, useTemplateRef } from "vue";

import { IWtcMultiSelectItem, IWtcMultiSelectOptions } from "../../interfaces/components.interfaces";

interface WtcMultiSelectPassThroughOptions extends MultiSelectPassThroughOptions {
    wtcList?: string;
}

const props = withDefaults(
    defineProps<{
        options: IWtcMultiSelectOptions[];
        emptyMessage?: string;
        scrollHeight?: string;
        placeholder?: string;
        maxDisplayOptions?: number;
        expandableOptions?: boolean;
        pt?: WtcMultiSelectPassThroughOptions;
    }>(),
    {
        options: () => [],
        placeholder: "Select Option",
        maxDisplayOptions: 3,
        expandableOptions: false,
        pt: () => {
            return {};
        }
    }
);

// Primevue's 'MultiSelect' (and other overlay-components) hide the overlay-container (closing the dropdown) on window 'resize'-events.
// Updating the height of an iframe (i.e. using our the iframe-listener-script) counts as resize from the perspective of the embedded web-page.
// If the 'Multiselect' is used to filter the content and the height of the iframe is updated, the opened dropdown will be closed.
// The kind of HACKY solution for now, is to set the 'bindResizeListener' to an empty function: see https://github.com/primefaces/primevue/blob/master/packages/primevue/src/multiselect/MultiSelect.vue#L801
const multiSelectEl = useTemplateRef("multiSelectEl");
onMounted(() => {
    // @ts-ignore
    multiSelectEl.value.bindResizeListener = () => {};
});

const optionGroupToggle = ref<number>();
const toggleOptionGroup = (index: number) => {
    if (optionGroupToggle.value === index) {
        optionGroupToggle.value = undefined;
    } else {
        optionGroupToggle.value = index;
    }
};
const isOptionGroupOpen = (index: number) => optionGroupToggle.value === index;

const selectedOptions = defineModel<IWtcMultiSelectItem[]>();

const selectedOptionLabels = computed(() => selectedOptions.value?.map((item) => item.label));
const selectedOptionValues = computed(() => selectedOptions.value?.map((item) => item.value));

const selectedOptionsDisplayValue = computed(() => {
    if (selectedOptionLabels.value.length <= 0) {
        return props.placeholder;
    }

    const additionalSelectionAmount = selectedOptionLabels.value.length - props.maxDisplayOptions;
    return `${selectedOptionLabels.value.slice(0, props.maxDisplayOptions).join(", ")}${additionalSelectionAmount > 0 ? `, +${additionalSelectionAmount}` : ""}`;
});

const isItemGroupSelected = (items: IWtcMultiSelectItem[]) =>
    items.some((_item) => selectedOptionValues.value.includes(_item.value));

const isEveryItemGroupSelected = (items: IWtcMultiSelectItem[]) =>
    items.every((_item) => selectedOptionValues.value.includes(_item.value));

const toggleGroupSelect = (items: IWtcMultiSelectItem[]) => {
    const itemsValues = items.map((_item) => _item.value);
    if (isItemGroupSelected(items)) {
        // remove all items of group
        selectedOptions.value = selectedOptions.value.filter((_item) => !itemsValues.includes(_item.value));
    } else {
        selectedOptions.value.push(...items);
    }
};

const isItemSelected = (item: IWtcMultiSelectItem) => selectedOptions.value.some((_item) => _item.value === item.value);

const toggleItemSelect = (item: IWtcMultiSelectItem) => {
    if (isItemSelected(item)) {
        // remove item
        selectedOptions.value = selectedOptions.value.filter((_item) => _item.value !== item.value);
    } else {
        selectedOptions.value.push(item);
    }
};
</script>

<style lang="scss"></style>
