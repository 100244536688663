<template>
    <WtcChannel :channel-name="channelNames.Schedule" use-transition @update:channel="scheduleChannel = $event">
        <template #slot__result>
            <div class="schedule__by-time">
                <header v-if="athConfig.isScheduleDisciplineFilterEnabled">
                    <Select
                        v-model="disciplineFilter"
                        optionLabel="Name"
                        :options="sortedDisciplines"
                        optionValue="Code"
                        :placeholder="$getStrByLng().CONTROL_FILTER_BY_DISCIPLINE"
                        show-clear
                    >
                    </Select>
                </header>
                <template v-for="(unitsOfDay, index) in filteredUnitsPerDay" :key="index">
                    <div
                        v-if="unitsOfDay && unitsOfDay.length > 0"
                        class="bg-surface font-normal_medium sticky top-0 mb-2 mt-4 first:mt-0"
                    >
                        <WtcDateTime :date="unitsOfDay.at(0)?.Date" format="dddd, DD. MMMM"></WtcDateTime>
                    </div>
                    <table class="w-full">
                        <tbody>
                            <tr
                                v-for="unit in unitsOfDay"
                                :key="unit.Rsc?.Value"
                                :class="
                                    unit.Stats?.CompType === AthDisciplineTypes.NON_EVENT
                                        ? 'cursor-none'
                                        : 'cursor-pointer'
                                "
                                @click="
                                    unit.Stats?.CompType !== AthDisciplineTypes.NON_EVENT &&
                                        routeToResult(unit.Rsc, unit.Stats?.Type)
                                "
                            >
                                <td class="w-14 min-w-14 text-left">
                                    <!-- Start time -->
                                    <WtcDateTime :date="unit.StartTime" format="HH:mm"></WtcDateTime>
                                </td>
                                <td class="w-20 min-w-20 text-left">
                                    <FalconStatusBatch
                                        class="!text-left"
                                        :status-code="unit.Status"
                                    ></FalconStatusBatch>
                                </td>
                                <!--  Competition Names  -->
                                <td class="text-brand-secondary text-left">
                                    <div class="flex items-center">
                                        <WtcImage
                                            v-if="unit.Stats.DiamondType"
                                            class="mr-3 w-4"
                                            :url="diamondImageUrl"
                                        ></WtcImage>
                                        <span class="first:ml-7">{{ useTranslatedProperty(unit, "EventName") }}</span>
                                        <span
                                            >,
                                            {{
                                                getCombinedValue(
                                                    useTranslatedProperty(unit, "PhaseName"),
                                                    useTranslatedProperty(unit, "UnitName"),
                                                    ", "
                                                )
                                            }}</span
                                        >
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </div>
        </template>
    </WtcChannel>
</template>

<script setup lang="ts">
import { FalconStatusBatch, useFalconStore, useTranslatedProperty } from "@swisstiming/falcon-core";
import { getCombinedValue, IScheduleChannel, WtcChannel, WtcDateTime, WtcImage } from "@swisstiming/webtec-kit";
import { useScheduleData } from "@swisstiming/webtec-kit";
import { useLocalStorage } from "@vueuse/core";
import { Dropdown } from "floating-vue";
import Select from "primevue/select";
import { computed, ref } from "vue";

import diamondImageUrl from "../assets/images/icon_diamond_large.png";
import { useAthChannels, useAthConfig } from "../composables/ath-data.composables";
import { AthDisciplineTypes } from "../enums/ath.enums";
import { useAthRoutes } from "../utils/ath-router.utils";

const athConfig = useAthConfig();
const disciplineFilter = useLocalStorage(useFalconStore().config.tournamentId + "_disciplineFilter", undefined);
const scheduleChannel = ref<IScheduleChannel>({});
const channelNames = useAthChannels();
const { sortedDisciplines, unitsGroupedByDay } = useScheduleData(scheduleChannel);
const { routeToResult } = useAthRoutes();

const filteredUnitsPerDay = computed(() => {
    if (disciplineFilter.value !== "" && disciplineFilter.value !== undefined && disciplineFilter.value !== null) {
        return Object.fromEntries(
            Object.entries(unitsGroupedByDay.value).map(([key, units]) => [
                key,
                units.filter((unit) => unit.Rsc.LongEvent === disciplineFilter.value)
            ])
        );
    }
    return unitsGroupedByDay.value;
});
</script>

<style scoped lang="scss"></style>
