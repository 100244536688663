<template>
    <component :is="resultComponent" :unit-rsc="unitRsc"></component>
</template>

<script setup lang="ts">
import { AthDisciplineTypes } from "ath-lr/src/ath.enums";
import { computed, toRef } from "vue";

import { useUnitTimingChannel } from "../../composables/ath-data.composables";
import AthHighJumpPage from "./AthHighJumpPage.vue";
import AthLongJumpPage from "./AthLongJumpPage.vue";
import AthRunPage from "./AthRunPage.vue";

const props = defineProps<{
    unitRsc?: string;
}>();

const { unitTimingData } = useUnitTimingChannel(toRef(props, "unitRsc"));

const resultComponent = computed(() => {
    switch (unitTimingData.value?.Stats?.Type) {
        case AthDisciplineTypes.RUN:
        case AthDisciplineTypes.RELAY:
            return AthRunPage;
        case AthDisciplineTypes.WIDTH:
            return AthLongJumpPage;
        case AthDisciplineTypes.HEIGHT:
            return AthHighJumpPage;
        default:
            return AthRunPage;
    }
});
</script>

<style scoped lang="scss"></style>
