<template>
    <WtcChannel :channel-name="channelName" use-transition>
        <template #slot__result>
            <div class="ath__high-jump">
                <AthResultHeader :rsc="unitRsc"></AthResultHeader>
                <div class="max-tablet:overflow-x-auto">
                    <table class="w-full">
                        <thead>
                            <tr>
                                <th class="sticky left-0 w-10 min-w-10">{{ $getStrByLng().HEADER_RANK }}</th>
                                <th class="w-10 min-w-10">{{ $getStrByLng().HEADER_START_POS }}</th>
                                <AthBibHeadColumn class="w-10 min-w-10"></AthBibHeadColumn>
                                <th class="w-16 min-w-16">{{ $getStrByLng().HEADER_NAT }}</th>
                                <th class="text-left">{{ $getStrByLng().HEADER_NAME }}</th>
                                <th class="w-24 min-w-24">{{ $getStrByLng().HEADER_BEST }}</th>
                                <AthDiamondRaceHeadColumns v-if="isDiamondQualiRace"></AthDiamondRaceHeadColumns>
                                <AthCombinedPointsHeadColumns
                                    v-if="isCombinedDiscipline"
                                ></AthCombinedPointsHeadColumns>
                                <th v-for="attempt in unitTimingData.Splits" :key="attempt.Id" class="w-16 min-w-16">
                                    {{ attempt.Name }}
                                </th>
                                <AthBestHeadColumns></AthBestHeadColumns>
                            </tr>
                        </thead>
                        <WtcTransitionGroup name="list-sort" tag="tbody">
                            <tr v-for="competitor in sortedResultList as IAthCompetitorDetail[]" :key="competitor.Id">
                                <FalconRankIrm class="sticky left-0" :competitor></FalconRankIrm>
                                <td>{{ competitor.StartPos }}</td>
                                <AthBibColumn :competitor></AthBibColumn>
                                <FalconFlag :nat-code="competitor.Nationality" tag="td"></FalconFlag>
                                <AthNameColumn :competitor></AthNameColumn>
                                <!-- No additional content for the complex result case-->
                                <AthComplexResultColumn :competitor></AthComplexResultColumn>

                                <AthDiamondRaceColumns
                                    v-if="isDiamondQualiRace"
                                    :competitor
                                    :diamond-id="unitTimingData.Stats?.DiamondId"
                                ></AthDiamondRaceColumns>

                                <AthCombinedPointsColumns
                                    v-if="isCombinedDiscipline"
                                    :competitor="competitor as IAthCompetitorDetail"
                                ></AthCombinedPointsColumns>

                                <td v-for="attempt in competitor.Intermediate" :key="attempt.Number">
                                    <AthAttemptBadge :attempt="attempt.Result"></AthAttemptBadge>
                                </td>
                                <AthBestColumns :competitor="competitor as IAthCompetitorDetail"></AthBestColumns>
                            </tr>
                        </WtcTransitionGroup>
                    </table>
                </div>
            </div>
        </template>
    </WtcChannel>
</template>

<script setup lang="ts">
import { FalconFlag, FalconRankIrm } from "@swisstiming/falcon-core";
import { WtcChannel, WtcTransitionGroup } from "@swisstiming/webtec-kit";
import { toRef } from "vue";

import AthAttemptBadge from "../../components/elements/results/AthAttemptBadge.vue";
import AthResultHeader from "../../components/elements/results/AthResultHeader.vue";
import AthBestColumns from "../../components/elements/tables/AthBestColumns.vue";
import AthBestHeadColumns from "../../components/elements/tables/AthBestHeadColumns.vue";
import AthBibColumn from "../../components/elements/tables/AthBibColumn.vue";
import AthBibHeadColumn from "../../components/elements/tables/AthBibHeadColumn.vue";
import AthCombinedPointsColumns from "../../components/elements/tables/AthCombinedPointsColumns.vue";
import AthCombinedPointsHeadColumns from "../../components/elements/tables/AthCombinedPointsHeadColumns.vue";
import AthComplexResultColumn from "../../components/elements/tables/AthComplexResultColumn.vue";
import AthDiamondRaceColumns from "../../components/elements/tables/AthDiamondRaceColumns.vue";
import AthDiamondRaceHeadColumns from "../../components/elements/tables/AthDiamondRaceHeadColumns.vue";
import AthNameColumn from "../../components/elements/tables/AthNameColumn.vue";
import { useUnitTimingChannel } from "../../composables/ath-data.composables";
import { IAthCompetitorDetail } from "../../interfaces/ath.interfaces";
const props = defineProps<{
    unitRsc?: string;
}>();

const { unitTimingData, channelName, isCombinedDiscipline, isDiamondQualiRace, sortedResultList } =
    useUnitTimingChannel(toRef(props, "unitRsc"));
</script>

<style scoped lang="scss"></style>
