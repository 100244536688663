<template>
    <td>
        <div class="flex flex-col gap-0.5 text-left">
            <WtcName :competitor-name="competitor" :href="hrefToAthleteBios" :tag="nameTag" target="_blank"></WtcName>
            <span v-if="competitor.Club" class="text-content/60 text-xs">{{ competitor.Club }}</span>
            <span v-if="useTeamMember && competitor.Children?.length > 0" class="team-member text-xs italic">
                <template v-for="(member, index) in competitor.Children" :key="member.Id">
                    {{ member.Name }}&nbsp;{{ member.FirstNameShort }}
                    <template v-if="index < competitor.Children.length - 1">,&nbsp;</template>
                </template>
            </span>
        </div>
    </td>
</template>

<script setup lang="ts">
import { ICommonCompetitor, WtcName } from "@swisstiming/webtec-kit";
import { computed } from "vue";

import { useAthStore } from "../../../stores/ath.store";
const { athConfig } = useAthStore();

const { competitor = {}, useTeamMember = false } = defineProps<{
    competitor?: ICommonCompetitor & { FedCode?: string; Club?: string };
    useTeamMember?: boolean;
}>();

const nameTag = computed(() => (competitor?.FedCode !== undefined && athConfig.isDiamondLeague ? "a" : "span"));
const hrefToAthleteBios = computed(() => `${document.referrer}/athletes/${competitor.FedCode}.html`);
</script>

<style scoped lang="scss"></style>
