import { padEnd } from "lodash-es";

export const RscCode = class Rsc {
    LongEvent: string;
    Event: string;
    Phase: string;
    Discipline: string;
    Gender: string;

    ValueEvent: string;
    ValuePhase: string;
    Value: string;

    IsDiscipline: boolean;
    IsEvent: boolean;
    IsGender: boolean;
    IsPhase: boolean;
    IsUnit: boolean;

    constructor(rscCode) {
        Object.defineProperty(this, "Value", {
            writable: false,
            enumerable: false,
            configurable: false,
            value: rscCode
        });
        if (this.Value !== undefined) {
            if (this.Value.length === 34) {
                Object.defineProperty(this, "Discipline", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(0, 3)
                });
                Object.defineProperty(this, "Gender", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(3, 1)
                });
                Object.defineProperty(this, "Event", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(4, 18)
                });
                Object.defineProperty(this, "Phase", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(22, 4)
                });
                Object.defineProperty(this, "Unit", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(26, 6)
                });
                Object.defineProperty(this, "SubUnit", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(32, 2)
                });
                Object.defineProperty(this, "LongEvent", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(0, 22)
                });
                Object.defineProperty(this, "ValuePhase", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: padEnd(this.LongEvent + this.Phase, 34, "-")
                });
                Object.defineProperty(this, "ValueEvent", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: padEnd(this.LongEvent, 34, "-")
                });

                Object.defineProperty(this, "IsDiscipline", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: padEnd(this.Discipline, 34, "-") === this.Value
                });
                Object.defineProperty(this, "IsGender", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: !this.IsDiscipline && padEnd(this.Discipline + this.Gender, 34, "-") === this.Value
                });
                Object.defineProperty(this, "IsEvent", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value:
                        !this.IsDiscipline &&
                        !this.IsGender &&
                        padEnd(this.Discipline + this.Gender + this.Event, 34, "-") === this.Value
                });
                Object.defineProperty(this, "IsPhase", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value:
                        !this.IsDiscipline &&
                        !this.IsGender &&
                        !this.IsEvent &&
                        padEnd(this.Discipline + this.Gender + this.Event + this.Phase, 34, "-") === this.Value
                });
                Object.defineProperty(this, "IsUnit", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: !this.IsDiscipline && !this.IsGender && !this.IsEvent && !this.IsPhase
                });
            } else if (this.Value.length === 9) {
                Object.defineProperty(this, "Discipline", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(0, 2)
                });
                Object.defineProperty(this, "Gender", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(2, 1)
                });
                Object.defineProperty(this, "Event", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(3, 3)
                });
                Object.defineProperty(this, "Phase", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(6, 1)
                });
                Object.defineProperty(this, "Unit", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(7, 2)
                });
                Object.defineProperty(this, "SubUnit", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: undefined
                });
                Object.defineProperty(this, "LongEvent", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: this.Value.substr(0, 6)
                });
                Object.defineProperty(this, "ValuePhase", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: padEnd(this.LongEvent + this.Phase, 9, "0")
                });
                Object.defineProperty(this, "ValueEvent", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: padEnd(this.LongEvent, 9, "0")
                });

                Object.defineProperty(this, "IsDiscipline", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: padEnd(this.Discipline, 9, "0") === this.Value
                });
                Object.defineProperty(this, "IsGender", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: !this.IsDiscipline && padEnd(this.Discipline + this.Gender, 9, "0") === this.Value
                });
                Object.defineProperty(this, "IsEvent", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value:
                        !this.IsDiscipline &&
                        !this.IsGender &&
                        padEnd(this.Discipline + this.Gender + this.Event, 9, "0") === this.Value
                });
                Object.defineProperty(this, "IsPhase", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value:
                        !this.IsDiscipline &&
                        !this.IsGender &&
                        !this.IsEvent &&
                        padEnd(this.Discipline + this.Gender + this.Event + this.Phase, 9, "0") === this.Value
                });
                Object.defineProperty(this, "IsUnit", {
                    writable: false,
                    enumerable: false,
                    configurable: false,
                    value: !this.IsDiscipline && !this.IsGender && !this.IsEvent && !this.IsPhase
                });
            }
        }
    }
};
