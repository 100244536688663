<template>
    <MultiSelect
        v-model="selectedOptions"
        class="custom-multi-select"
        :emptyMessage
        option-group-label="label"
        :options
        overlay-class="custom-multi-select-overlay"
        :pt
        :scrollHeight
        :show-toggle-all="false"
    >
        <template #value>
            <slot name="value">{{ selectedOptionsDisplayValue }}</slot>
        </template>
        <template #optiongroup="{ option }">
            <div class="flex flex-col gap-5">
                <div
                    class="font-normal_bold flex cursor-pointer select-none items-center gap-2 leading-none"
                    @click="toggleGroupSelect(option.items)"
                >
                    <font-awesome-icon
                        :icon="
                            isEveryItemGroupSelected(option.items)
                                ? faSquareCheck
                                : isItemGroupSelected(option.items)
                                  ? faSquareMinus
                                  : faSquare
                        "
                    />
                    <span>{{ option.label }}</span>
                </div>
                <ul class="grid grid-flow-col grid-rows-6 gap-4 gap-x-8">
                    <li
                        v-for="item in option.items"
                        :key="item.value"
                        class="flex cursor-pointer select-none items-center gap-2"
                        @click="toggleItemSelect(item)"
                    >
                        <font-awesome-icon :icon="isItemSelected(item) ? faSquareCheck : faSquare" />
                        <span class="leading-none">{{ item.label }}</span>
                    </li>
                </ul>
            </div>
        </template>
    </MultiSelect>
</template>

<script setup lang="ts">
import { faSquare, faSquareCheck, faSquareMinus } from "@fortawesome/free-regular-svg-icons";
import MultiSelect, { MultiSelectPassThroughOptions } from "primevue/multiselect";
import { computed } from "vue";

import { IWtcMultiSelectItem, IWtcMultiSelectOptions } from "../../interfaces/components.interfaces";

const props = withDefaults(
    defineProps<{
        options: IWtcMultiSelectOptions[];
        emptyMessage?: string;
        scrollHeight?: string;
        placeholder?: string;
        maxDisplayOptions?: number;
        pt?: MultiSelectPassThroughOptions;
    }>(),
    {
        options: () => [],
        placeholder: "Select Option",
        maxDisplayOptions: 3,
        pt: () => {
            return {};
        }
    }
);

const selectedOptions = defineModel<IWtcMultiSelectItem[]>();

const selectedOptionLabels = computed(() => selectedOptions.value?.map((item) => item.label));
const selectedOptionValues = computed(() => selectedOptions.value?.map((item) => item.value));

const selectedOptionsDisplayValue = computed(() => {
    if (selectedOptionLabels.value.length <= 0) {
        return props.placeholder;
    }

    const additionalSelectionAmount = selectedOptionLabels.value.length - props.maxDisplayOptions;
    return `${selectedOptionLabels.value.slice(0, props.maxDisplayOptions).join(", ")}${additionalSelectionAmount > 0 ? `, +${additionalSelectionAmount}` : ""}`;
});

const isItemGroupSelected = (items: IWtcMultiSelectItem[]) =>
    items.some((_item) => selectedOptionValues.value.includes(_item.value));

const isEveryItemGroupSelected = (items: IWtcMultiSelectItem[]) =>
    items.every((_item) => selectedOptionValues.value.includes(_item.value));

const toggleGroupSelect = (items: IWtcMultiSelectItem[]) => {
    const itemsValues = items.map((_item) => _item.value);
    if (isItemGroupSelected(items)) {
        // remove all items of group
        selectedOptions.value = selectedOptions.value.filter((_item) => !itemsValues.includes(_item.value));
    } else {
        selectedOptions.value.push(...items);
    }
};

const isItemSelected = (item: IWtcMultiSelectItem) => selectedOptions.value.some((_item) => _item.value === item.value);

const toggleItemSelect = (item: IWtcMultiSelectItem) => {
    if (isItemSelected(item)) {
        // remove item
        selectedOptions.value = selectedOptions.value.filter((_item) => _item.value !== item.value);
    } else {
        selectedOptions.value.push(item);
    }
};
</script>

<style lang="scss"></style>
