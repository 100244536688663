<template>
    <a :href="binaryFileUrl" target="_blank">{{ title }}</a>
</template>
<script setup lang="ts">
import { computed } from "vue";

import { usePSBinaryEntry } from "../../composables/binaries.composables";

const props = defineProps({
    tournamentId: String,
    nameSpace: String,
    binaryName: String,
    title: String
});

const { binaryFileUrl } = usePSBinaryEntry(
    computed(() => props.binaryName?.replace(".", "_")?.toUpperCase()),
    computed(() => props.nameSpace),
    computed(() => props.tournamentId)
);
</script>

<style scoped lang="scss"></style>
