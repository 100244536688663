<template>
    <td>{{ competitorEntry?.Rank }}</td>
    <td>{{ competitorEntry?.Result }}</td>
</template>
<script setup lang="ts">
import { ICompetitorDetail } from "@swisstiming/webtec-kit";
import { computed, toRef } from "vue";

import { useDiamondStanding } from "../../../composables/ath-data.composables";

const props = defineProps<{
    diamondId: string;
    competitor?: ICompetitorDetail;
}>();

const { diamondStanding } = useDiamondStanding(toRef(props, "diamondId"));

const competitorEntry = computed(() => diamondStanding.value.Competitors?.[props.competitor.AthleteId]);
</script>

<style scoped lang="scss"></style>
