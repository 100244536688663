<template>
    <template v-if="athConfig.isPBnSBEnabled">
        <td>{{ competitor?.Stats?.SB }}</td>
        <td class="border-content/20 border-r">
            {{ competitor?.Stats?.PB }}
        </td>
    </template>
</template>
<script setup lang="ts">
import { IAthCompetitorDetail } from "../../../interfaces/ath.interfaces";
import { useAthStore } from "../../../stores/ath.store";

defineProps<{
    competitor?: IAthCompetitorDetail;
}>();

const { athConfig } = useAthStore();
</script>

<style scoped lang="scss"></style>
