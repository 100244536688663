<template>
    <WtcChannel :channel-name="channelName">
        <template #slot__result>
            <div class="max-tablet:overflow-x-auto">
                <table class="w-full">
                    <thead>
                        <tr>
                            <th class="sticky left-0 w-14 min-w-14">{{ $getStrByLng().HEADER_RANK }}</th>
                            <AthBibHeadColumn class="w-14 min-w-14"></AthBibHeadColumn>
                            <th class="w-20 min-w-20 text-left">{{ $getStrByLng().HEADER_NAT }}</th>
                            <th class="text-left">{{ $getStrByLng().HEADER_NAME }}</th>
                            <th class="w-40 min-w-40 text-left">{{ $getStrByLng().HEADER_TIME }}</th>
                        </tr>
                    </thead>
                    <WtcTransitionGroup name="list-sort" tag="tbody">
                        <tr v-for="competitor in sortedResultList as IAthCompetitorDetail[]" :key="competitor.Id">
                            <AthRankColumn class="sticky left-0" :competitor></AthRankColumn>
                            <AthBibColumn :competitor></AthBibColumn>
                            <FalconFlag :nat-code="competitor.Nationality" tag="td"></FalconFlag>
                            <AthNameColumn :competitor use-team-member></AthNameColumn>

                            <!-- Time -->
                            <AthComplexResultColumn
                                :class="{
                                    '!bg-surface-highlightLight': competitor.Stats?.Live_LeaderLap !== undefined
                                }"
                                :competitor
                            >
                                <!-- All the run specific additions being displayed along with the time -->
                                <WtcImage v-if="competitor.Stats?.Pace" class="mr-3 w-5" :url="paceImageUrl"></WtcImage>
                                <span v-if="displaySplitTimes" class="text-xs">{{ competitor.Stats?.Live_Split }}</span>
                                <span
                                    v-if="competitor.Stats?.FalseStart"
                                    class="text-content-negative text-nowrap px-1 text-xs"
                                    >{{ $getStrByLng().MESSAGE_FALSE_START }}</span
                                >
                            </AthComplexResultColumn>
                        </tr>
                    </WtcTransitionGroup>
                </table>
            </div>
        </template>
    </WtcChannel>
</template>
<script setup lang="ts">
import { FalconFlag } from "@swisstiming/falcon-core";
import { ScheduleStatus, WtcChannel, WtcImage, WtcTransitionGroup } from "@swisstiming/webtec-kit";
import { computed, toRef } from "vue";

import paceImageUrl from "../../../assets/images/icon_pacemaker.svg";
import { useAthTimingChannel } from "../../../composables/ath-data.composables";
import { IAthCompetitorDetail } from "../../../interfaces/ath.interfaces";
import AthBibColumn from "../tables/AthBibColumn.vue";
import AthBibHeadColumn from "../tables/AthBibHeadColumn.vue";
import AthComplexResultColumn from "../tables/AthComplexResultColumn.vue";
import AthNameColumn from "../tables/AthNameColumn.vue";
import AthRankColumn from "../tables/AthRankColumn.vue";

const props = defineProps<{ rsc: string }>();

const { sortedResultList, timingData, channelName } = useAthTimingChannel(toRef(props, "rsc"));

const displaySplitTimes = computed(
    () => timingData.value.Status !== ScheduleStatus.Official && timingData.value.Stats?.LiveSplits
);
</script>

<style scoped lang="scss"></style>
